import { useEffect, useState } from 'react';
import { Form, Container, Button, Row, Col, Modal } from 'react-bootstrap';
import { Link ,useHistory} from 'react-router-dom';
import { toast } from 'react-toastify';
import { formValidation, userService } from '../_services';
import { Loader } from './Loader';
import MedicalHistoryRecord from './MedicalHistory';

const MedicalRecord = () => {
    const [showloader, setShow] = useState({ display: "none" });
    const [myDiseases, setMyDiseases] = useState([
        { "I": { "disease": "", "date": new Date(Date.now()), "age": "", "stage": "", "type": "" } },
        { "M": { "disease": "", "date": new Date(Date.now()), "age": "", "stage": "", "type": "" } },
        { "F": { "disease": "", "date": new Date(Date.now()), "age": "", "stage": "", "type": "" } },
        { "S": { "disease": "", "date": new Date(Date.now()), "age": "", "stage": "", "type": "" } }
    ]);
    const [diseaseList, setDiseaseList] = useState([]);
    const [showerr, setErr] = useState(false);

    const prop = { myDiseases, setMyDiseases, setErr };
    const history=useHistory();

    useEffect(() => {
        triggerLoader("flex");
        userService.get_diseases().then((resp) => {
            triggerLoader("none");
            setDiseaseList(resp.data.data);
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            triggerLoader("none");
        });
    }, [])

    function handleSubmit(event: any) {
        event.preventDefault();
        var medical_disease = "";
        var medical_for = "";
        var stage = "";
        var date_diagnosed = "";
        var age = "";
        let validate = formValidation.Diseases_validation(myDiseases)
        if (validate) {
            var oldDisease = myDiseases;
            oldDisease.map((recordType: any, i: any) => {
                var keys = Object.keys(recordType);
                var arr_length = recordType[keys[0]].length;
                if (arr_length) {
                    var arr = recordType[keys[0]];
                    arr.map((data: any, dIndex: any) => {
                        console.log('\n arr...', data);
                        // var dt = new Date(data.date)
                        // var val_date = dt.getDate() + "-" + dt.getMonth() + "-" + dt.getFullYear()
                        if (date_diagnosed == "") {
                            medical_disease = data.disease
                            medical_for = data.type
                            stage = data.stage
                            date_diagnosed = data.date
                            if (data.age) {
                                age = data.age
                            } else {
                                age = "0"
                            }
                        } else {
                            medical_disease = medical_disease + "~" + data.disease
                            medical_for = medical_for + "~" + data.type
                            stage = stage + "~" + data.stage
                            date_diagnosed = date_diagnosed + "~" + data.date
                            if (data.age) {
                                age = age + "~" + data.age
                            } else {
                                age = age + "~" + "0"
                            }
                        }
                    })
                }
            })
            triggerLoader("flex");

            userService.add_MedicalHistory({
                "medical_disease": medical_disease,
                "medical_for": medical_for,
                "stage": stage,
                "date_diagnosed": date_diagnosed,
                "age": age
            }).then((resp) => {
                toast.success('Medical history added successfully.');
                triggerLoader("none");
                localStorage.setItem('left_menu_option','third');
                setTimeout(()=>{
                    history.push('/');
                },1000);
            }).catch((err) => {
                if (err.response.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
                toast.error('An error occured while saving details. Please try after some time.');
                triggerLoader("none");
            })
        } else {
            setErr(true);
            return false
        }
    }

    function triggerLoader(status: any) {
        setShow(prevShow => {
            return {
                ...prevShow,
                display: status
            }
        })
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <Link to="/"><img src={require('../images/back.svg').default} alt="back" /></Link>
                                </h5>
                                <h2>Add Medical Record</h2>
                            </Col>
                            <Col md={12}><hr /></Col>
                        </Row>
                        <Row className="metrics_row medical-record-page">
                            <Col lg={7} md={8}>
                                <Form onSubmit={(event: any) => handleSubmit(event)}>
                                    <Row className="metric_form">
                                        <Col md={12}>
                                            <div className="prescription-delete">
                                                <h3>Your Medical History</h3>
                                            </div>
                                        </Col>
                                        <MedicalHistoryRecord list={diseaseList} {...prop} type="I" />
                                        <Col md={12}><hr /></Col>
                                        <Col md={12}>
                                            <div className="mt-4">
                                                <div className="prescription-delete">
                                                    <h3>Mother’s Medical History</h3>
                                                </div>
                                            </div>
                                        </Col>
                                        <MedicalHistoryRecord {...prop} list={diseaseList} type="M" />
                                        <Col md={12}><hr /></Col>
                                        <Col md={12}>
                                            <div className="mt-4">
                                                <div className="prescription-delete">
                                                    <h3>Father’s Medical History</h3>
                                                </div>
                                            </div>
                                        </Col>
                                        <MedicalHistoryRecord {...prop} list={diseaseList} type="F" />
                                        <Col md={12}><hr /></Col>
                                        <Col md={12}>
                                            <div className="mt-4">
                                                <div className="prescription-delete">
                                                    <h3>Sibling’s Medical History</h3>
                                                </div>
                                            </div>
                                        </Col>
                                        <MedicalHistoryRecord {...prop} list={diseaseList} type="S" />
                                        <Col md={12}>
                                            <Button variant="unset" className="btnsumit" type="submit">Submit</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <Modal show={showerr} onHide={() => { setErr(false) }} size="sm">
                <Modal.Header closeButton className="border-0 headerclose">
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <img src={require('../../src/images/check.svg').default} />
                    <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
                    <p className="mb-0">Please fill all required field</p>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MedicalRecord;