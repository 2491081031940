import { useState } from "react";
import { Form } from "react-bootstrap"
import { userService, formValidation } from '../../_services';
import { Loader } from '../Loader';
import { BLOOD_GROUP_TYPE } from '../../config/constants';

const Step4 = (props: any) => {
    const [show, setShow] = useState("none");
    const [err, setErr] = useState("") as any;

    const errors: any = {
        height: "",
        weight: "",
        bloodGroup: "",
        formIsValid: false,
    };
    // let bloodgroups: any = ["O-", "O+", "A-", "A+", "B-", "B+", "AB-", "AB+"];
    
    const handlchangedata = (e: any, fieldname: any) => {
        if (fieldname == 'height') {
            props.defaultData.height = e;
        }
        if (fieldname == 'weight') {
            props.defaultData.weight = e;
        }
        if (fieldname == 'bloodGroup') {
            props.defaultData.bloodGroup = e;
        }
    }

    let options: any = [];

    if (BLOOD_GROUP_TYPE) {
        options = BLOOD_GROUP_TYPE.map(function (ques: any, index: any) {
            return (<option value={ques} key={`${ques}_${index}`}>{ques}</option>)
        });
    }
    const nextstep3 = (event: any) => {
        event.preventDefault();
        let params = {
            height: props.defaultData.height,
            weight: props.defaultData.weight,
            bloodGroup: props.defaultData.bloodGroup
        };
        let validate = formValidation.step3_validation(params, errors);
        if (validate.formIsValid) {
            setShow("flex");
            var formData = new FormData();
            formData.append("height", params.height);
            formData.append("weight", params.weight);
            formData.append("bloodGroup", params.bloodGroup);
            userService.createUserProfile(formData).then(function (response: any) {
                setShow("none");
                props.next()
            }).catch(function (error: any) {
                setShow("none");
            });

            // userService.createProfileStep4(props.defaultData).then(function (response: any) {
            //     setShow("none");
            //     props.next()
            // }).catch(function (error: any) {
            //     setShow("none");
            // });
        } else {
            setErr(validate)
        }
    }

    return (
        <section className="loginsec">
            <Loader status={show} />
            <div className="right_side py-5">
                <div className="container">
                    <div className="row">
                        <aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
                            <div></div>
                            <div className="login_form mt-4">
                                <div className="text-center">
                                    <p className="text-right">
                                        <span className="skip" onClick={props.next}>SKIP</span>
                                    </p>
                                    <p className="text-secondary">STEP 4</p>
                                    {props.defaultData.editProfile ? (<h4>Update Your Profile</h4>) : (<h4>Create Your Profile</h4>)}
                                    <p>Entering your Height and Weight will help the Xcellent Life App to
                                        track your BMI and allow for specific recommendations on
                                        measures to take to improve your health.</p>
                                    <p>If you enter your blood type, the app will maintain it as a part of your
                                        profile and identify any specific health risk that your blood type
                                        may be more likely to experience. Everything we do on the app is
                                        intended to improve quality of life by advancing wellness.</p>
                                </div>

                                <Form className="mt-4" autoComplete="off" onSubmit={(event: any) => nextstep3(event)}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="height">
                                                <Form.Control type="text" placeholder="Height(IN)" className="input103" defaultValue={props.defaultData.height} onChange={(e: any) => handlchangedata(e.target.value, 'height')} />
                                                {err ? (<span className="errors">{err.height}</span>) : (<></>)}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-6">
                                            <Form.Group className="mb-3" controlId="weight">
                                                <Form.Control type="text" placeholder="Weight(lb)" className="input103" defaultValue={props.defaultData.weight} onChange={(e: any) => handlchangedata(e.target.value, 'weight')} />
                                                {err ? (<span className="errors">{err.weight}</span>) : (<></>)}
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3" controlId="bloodGroup">
                                                <Form.Control as="select" className="input103 mb-3" defaultValue={props.defaultData.bloodGroup} onChange={(e: any) => handlchangedata(e.target.value, 'bloodGroup')}>
                                                    <option value="">Choose one</option>
                                                    {options}
                                                </Form.Control>
                                                {/* <Form.Control type="text" placeholder="Blood Type" className="input103" defaultValue={props.defaultData.bloodGroup} onChange={(e:any)=>handlchangedata(e.target.value,'bloodGroup')}/> */}
                                                {err ? (<span className="errors">{err.bloodGroup}</span>) : (<></>)}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="button" onClick={props.prev} className="backbtn btn shadow text-white mr-2" >Back</button>
                                        <button type="submit" className="loginbtn btn shadow text-white">Next</button>
                                        <p className="forgotpassword">Profile 80% Complete </p>
                                    </div>
                                </Form>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Step4;