export const formValidation = {
  step4_validation,
  step1_validation,
  step3_validation,
  step_validation,
  addpres_Validation,
  Diseases_validation,
  ageValidation
};

function step1_validation(params: any, errors: any) {
  let fields = params;
  // let emailvalid = false
  errors.formIsValid = true;
  if (!fields["firstname"] || fields["firstname"].trim() == "" || fields["firstname"] == null) {
    errors.formIsValid = false;
    errors.firstname = 'First Name field can’t be empty.';

  } else if (fields["firstname"] == null || fields["firstname"].trim() == "") {
    errors.formIsValid = false;
    errors.firstname = 'First Name field can’t be empty.';
  } else if (fields["firstname"] && fields["firstname"].length > 25 && fields["firstname"] !== "" && fields["firstname"] != null) {

    errors.formIsValid = false;
    errors.firstname = 'First name should be maximum 25 charchters.'

  } else {
    errors.firstname = "";
  }
  if (!fields["lastname"] || fields["lastname"].trim() == "" || fields["lastname"] == null) {
    errors.formIsValid = false;
    errors.lastname = 'Last Name field can’t be empty.';
  } else if (fields["lastname"] && fields["lastname"].length > 25 && fields["lastname"] !== "" && fields["lastname"] != null) {
    errors.formIsValid = false;
    errors.lastname = 'Last name should be maximum 25 charchters.'
  } else if (fields["lastname"] == null || fields["lastname"].trim() == "") {
    errors.formIsValid = false;
    errors.lastname = 'Last Name field can’t be empty.';
  } else {
    errors.lastname = "";
  }
  return errors;

}

function step3_validation(params: any, errors: any) {
  let fields = params;
  // let emailvalid = false
  errors.formIsValid = true;
  if (!fields["height"]) {
    errors.formIsValid = false;
    errors.height = 'Height field can’t be empty.'
  }
  if (fields["height"]) {

    if (fields["height"] % 1 != 0) {
      // alert(isNaN(fields["price"]) )
      if (isNaN(fields["height"])) {
        errors.formIsValid = false;
        errors.height = 'Enter a valid height.'
      } else {
        let tt = fields["height"].split(".")
        // if(tt[1].length>2) {
        errors.formIsValid = false;
        errors.height = 'Height should not have decimal.'
        // }

      }

    } else {
      if (fields["height"].length > 2) {
        errors.formIsValid = false;
        errors.height = 'Height   must be 2 digits or less.'
      }
    }
  }
  if (!fields["weight"]) {
    errors.formIsValid = false;
    errors.weight = 'Weight field can’t be empty.'
  }
  if (fields["weight"]) {

    if (fields["weight"] % 1 != 0) {
      // alert(isNaN(fields["price"]) )
      if (isNaN(fields["weight"])) {

        errors.formIsValid = false;
        errors.weight = 'Enter a valid weight.'

      }
    }
    if (fields["weight"].length > 4) {
      errors.formIsValid = false;
      errors.weight = 'Weight must be  4 digits or less.'
    }
  }
  console.log(fields["bloodGroup"], "===bl")
  if (!fields["bloodGroup"]) {
    errors.formIsValid = false;
    errors.bloodGroup = 'bloodGroup field can’t be empty.'
  }

  if (fields["bloodGroup"] == "Choose one") {
    errors.formIsValid = false;
    errors.bloodGroup = 'bloodGroup field can’t be empty.'
  }


  //  if(tt[0].length>8) {
  //   errors.formIsValid = false;
  //   throw new SubmissionError({ price: 'Height must be  8 digit or less.', _error: 'failed!' }) 
  //  }




  return errors;

}
function step_validation(params: any, errors: any) {
  let fields = params;
  // let emailvalid = false
  errors.formIsValid = true;
  if (!fields["gender"]) {
    errors.formIsValid = false;
    errors.gender = 'Please select a gender.'
  }

  // if (!fields["birthDate"]) {
  //   errors.formIsValid = false;
  //   errors.birthDate = 'birthDate field can’t be empty.'
  // }

  // if (!fields["location"]) {
  //   errors.formIsValid = false;
  //   errors.location = 'Location field can’t be empty.'
  // }

  // if (tt[0].length > 8) {
  //   errors.formIsValid = false;
  //   throw new SubmissionError({ price: 'Height must be  8 digit or less.', _error: 'failed!' })
  // }

  return errors;

}
function step4_validation(params: any, errors: any) {

  console.log(params)
  errors.formIsValid = true;
  if (!params.accountNumber || params.accountNumber.trim() === "") {
    errors.formIsValid = false;
    errors.accountNumber = "Account number field can't be empty";
  } else {
    if (params.accountNumber.length > 25) {
      errors.formIsValid = false;
      errors.accountNumber = "Account number length should be  25 digit.";
    } else {
      errors.accountNumber = ""
    }
  }

  if (!params.account_holder_name || params.account_holder_name.trim() === "") {
    errors.formIsValid = false;
    errors.account_holder_name = "Account Holder Name field can't be empty";
  }

  if (!params.routingNumber || params.routingNumber.trim() === "") {
    errors.formIsValid = false;
    errors.routingNumber = "Routing number field can't be empty";
  }
  else {
    errors.routingNumber = ""
  }

  if (!params.location || params.location.trim() === "") {
    errors.formIsValid = false;
    errors.location = "location field can't be empty";
  }
  else {
    errors.location = ""
  }

  if (!params.ssn_no || params.ssn_no.trim() === "") {
    errors.formIsValid = false;
    errors.ssn_no = "SSN number field can't be empty";
  }
  else {
    errors.ssn_no = ""
  }


  return errors;
}

function addpres_Validation(prescription: any) {
  let formIsValid = true;

  // at_time: ""
  // dosage: ""
  // dosage_unit: ""
  // end_time: ""
  // name: ""
  // start_time: ""
  // times_in_day: ""

  prescription.forEach(function (p: any) {
    if (p.prescription.name.trim() == "") {
      formIsValid = false
    }
    if (p.prescription.at_time.trim() == "") {
      formIsValid = false
    }
    if (p.prescription.dosage.trim() == "") {
      formIsValid = false
    }
    if (p.prescription.dosage_unit.trim() == "") {
      formIsValid = false
    }
    if (p.prescription.start_time.trim() == "") {
      formIsValid = false
    }
    if (p.prescription.end_time.trim() == "") {
      formIsValid = false
    }
    // if(p.prescription.times_in_day.trim()==""){
    //   formIsValid = false    
    // }  
  });

  return formIsValid;

}

function Diseases_validation(list: any) {
  let formIsValid = true;
  list.forEach((d: any, i: any) => {
    var keys = Object.keys(d);
    if (d[keys[0]].length) {
      var arr = d[keys[0]];
      arr.forEach((data: any) => {
        if (data.disease.trim() != "") {
          if (data.date == "") {
            formIsValid = false
          }
          if (data.stage.trim() == "") {
            formIsValid = false
          }
          if (data.type != "I" && data.age.trim() == "") {
            formIsValid = false
          }
        } else {
          formIsValid = false
        }
      });
    }
    return formIsValid
  })
  return formIsValid;
}

function ageValidation(age: any) {
  var regex = new RegExp("^[1-9].{0,1}|$");
  if (!regex.test(age.trim()) || age.trim().length > 2 || age.trim() == '00' || age.trim() == "0" || isNaN(age)) {
    return false
  } else {
    return true
  }
}