import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Modal, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { userService } from '../_services';
import moment from 'moment';
import { useHistory } from 'react-router';
import { Loader } from './Loader';
import { BP_RANGE } from '../config/constants';

const Bodyvitals = () => {
    let history = useHistory();
    const [value, onChange] = useState(new Date());
    const [show, setShow] = useState(false);
    const [BP, SetBP] = useState('');
    const [BP1, SetBP1] = useState('');
    const [showloader, setShowloader] = useState({ display: "none" });
    const [metricdata, setMetricData] = useState([]);
    const [fieldVals, setFieldVals] = useState(new Map);
    const [params, setParams] = useState({});
    const [showErr, setShowErr] = useState(false);

    useEffect(() => {
        triggerLoader("flex");
        localStorage.setItem("record", "yes");
        userService.metric_list(moment().format('YYYY-MM-DD')).then(response => {
            setMetricData(response.data.data.body_vital);
            // composing data for prefilling form
            if (response.data.data.body_vital.length) {
                const map = new Map() as any;
                let bp = '' as any, bp1 = '' as any, object = {} as any;
                response.data.data.body_vital.forEach((value: any) => {
                    // saving value of Blood pressure entry
                    if (value.metrics === "Blood Pressure") {
                        let bpSplit = value.value.split('/');
                        if (bpSplit.length > 1) {
                            bp = bpSplit[0];
                            bp1 = bpSplit[1];
                        } else {
                            if (value.value !== "0") {
                                bp = value.value;
                            }
                        }
                    } else {
                        let keys = value.metrics.toLowerCase().replace(" ", "_");
                        object[keys] = value.id + "_" + value.value;
                        map.set(value.id, value.value === "0" ? "" : value.value);
                    }
                });
                // setParams(value);
                setParams(object);
                SetBP(bp);
                SetBP1(bp1);
                setFieldVals(map);
            }
            triggerLoader("none");
        }).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            triggerLoader("none");
        })
    }, []);

    const submit = () => {
        var proceed = false;
        var keys = Object.keys(params);
        if (BP.length && BP1.length) {
            proceed = true;
        }

        if (keys.length == 0 && !proceed) {
            setShowErr(true);
            return false
        }
        triggerLoader("flex");
        let date = moment(value, 'DD-MM-YYYY').format('yyyy-MM-DD');
        var data = {} as any;
        if (BP.length && BP1.length) {
            data = {
                ...params,
                date_measured: date,
                "blood_pressure": "2_" + BP + "/" + BP1 + " " + "Systolic (mmHG)",
                user_id: localStorage.getItem('user_id')
            }
        } else {
            data = {
                ...params,
                date_measured: date,
                user_id: localStorage.getItem('user_id')
            }
        }
        let viaIsDetail = localStorage.getItem('viaIsDetail');
        if (viaIsDetail === "true") {
            data.competition_id = localStorage.getItem('comp_id');
        }
        userService.health_matrics(data).then(response => { return response.data }).then((result) => {
            // toast.success('Record added successfully.');
            triggerLoader("none");
            localStorage.removeItem("record");
            let viaInviteGroup = localStorage.getItem("viaInviteGroup");
            if (viaInviteGroup === "true") {
                let inviteGroupId = localStorage.getItem("inviteGroupId")
                if (inviteGroupId) {
                    localStorage.setItem("viaInviteGroup", "false");
                    history.push(`/invite_group?id=${inviteGroupId}`)
                } else {
                    history.push('/');
                }
            } else {
                history.push('/');
            }
        }).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            triggerLoader("none");
        });
    }

    function handleClose() {
        setShow(false);
        history.push("/");
    }

    function validVal(e: any) {
        let val = e.target.value;
        var regex = new RegExp("^[0-9]{0,6}$");
        if (!regex.test(val)) {
            e.preventDefault();
            return false
        } else {
            return true
        }
    }

    function handleChange(id: any, e: any, unit: any, metrics: any) {
        let val = e.target.value;
        var regex = new RegExp("^[0-9]{0,6}$");
        if (!regex.test(val)) {
            e.preventDefault()
            return false
        }
        var key = metrics.toLowerCase().replace(" ", "_");
        var key_value = id + "_" + val + " " + unit;
        let value = { ...params, [key]: key_value };
        setParams(value);
        let f_val = new Map(fieldVals);
        f_val.set(id, val);
        setFieldVals(f_val);
    }

    // trigger loader
    function triggerLoader(status: any) {
        setShowloader(prevShowloader => {
            return {
                ...prevShowloader,
                display: status
            }
        });
    }

    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box">
                        <Row className="bodyvitals_head">
                            <Col md={6}>
                                <h5>
                                    <a href="/">
                                        <img src={require('../images/back.svg').default} alt="back" />
                                    </a>
                                </h5>
                                <h2>Body Vitals</h2>
                            </Col>
                            <Col md={6} className="text-right">
                                <h6>Date Measured</h6>
                                <div className="clndr_icon_1">
                                    <DatePicker onChange={onChange} value={value} maxDate={new Date} format="MM-dd-y"/>
                                    <span><img src={require('../images/clndr1.svg').default} alt="clndr1" width="17px" /></span>
                                </div>
                            </Col>
                            <Col md={12}>
                                <hr />
                            </Col>
                        </Row>

                        <Row className="metrics_row">
                            <Col md={12}>
                                <h3>Health Metrics</h3>
                                <p>Record measurements for as many metrics as you can</p>
                            </Col>

                            {metricdata ?
                                metricdata.map((data: any, i: any) => {
                                    return (
                                        <React.Fragment key={`${data.id}_${i}`}>
                                            <Col lg={6} md={7}>
                                                <Form autoComplete="off" onSubmit={(event: any) => { event.preventDefault() }}>
                                                    <Row className="metric_form">
                                                        {data.metrics == 'Blood Pressure'
                                                            ?
                                                            <>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3" controlId="bloodPressureSystolic">
                                                                        <Form.Control value={BP} type="text" placeholder="Blood pressure systolic" onChange={(e: any) => {
                                                                            if (validVal(e)) {
                                                                                SetBP(e.target.value)
                                                                            }
                                                                        }} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group className="mb-3" controlId="diastolic">
                                                                        <Form.Control value={BP1} type="text" placeholder="Diastolic" onChange={(e: any) => {
                                                                            if (validVal(e)) {
                                                                                SetBP1(e.target.value)
                                                                            }
                                                                        }} />
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            :
                                                            <Col md={12}>
                                                                <Form.Group className="mb-3" controlId="metric">
                                                                    <Form.Control value={fieldVals && fieldVals.get(data.id) ? fieldVals.get(data.id) : ""} type="text" placeholder={data.metrics} onChange={(e: any) => handleChange(data.id, e, data.ranges[0].unit, data.metrics)} />
                                                                </Form.Group>
                                                            </Col>
                                                        }
                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Col lg={4} md={5} className="rightside_select">
                                                <Form autoComplete="off" onSubmit={(event: any) => { event.preventDefault() }}>
                                                    <div className="mb-3">
                                                        <Form.Group controlId={"ControlSelect1" + i}>
                                                            <Form.Control as="select">
                                                                {data.metrics == 'Blood Pressure'
                                                                    ?
                                                                    <option>mmHG</option>
                                                                    :
                                                                    <option>{data.ranges[0].unit}</option>
                                                                }
                                                            </Form.Control>
                                                        </Form.Group>
                                                        <div className="info-div-one">
                                                            <img src={require('../images/info.svg').default} alt="info" />
                                                            {
                                                                data.ranges.length ?
                                                                    <div className="systolic-box">
                                                                        {data.ranges.map((value: any, index: any) => {
                                                                            return (
                                                                                <React.Fragment key={`${value.id}_${index}`}>
                                                                                    {data.metrics === "Blood Pressure" ? <div className="d-flex good-one text-center"><p><b>{BP_RANGE[index]}</b></p></div> : ''}
                                                                                    <div className="d-flex good-one">
                                                                                        <p>Good:</p>
                                                                                        <p>{value.green}</p>
                                                                                    </div>
                                                                                    <div className="d-flex caution-one">
                                                                                        <p>Caution:</p>
                                                                                        <p>&lt;{value.yellow_low}(min)<br />&gt;{value.yellow_high}(max)</p>
                                                                                    </div>
                                                                                    <div className="d-flex alert-one alert-last">
                                                                                        <p>Alert Level:</p>
                                                                                        <p>{value.red_low}(min)<br></br>{value.red_high}(max)</p>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </div>
                                                    </div>
                                                </Form>
                                            </Col>
                                        </React.Fragment>
                                    )
                                })
                                : ""
                            }
                            <Col md={12}>
                                <Button variant="unset" type="button" className="btnsumit" onClick={submit}>Submit</Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <Modal show={show} onHide={handleClose} size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Record added successfully.</h5>
                </Modal.Body>
            </Modal>

            <Modal show={showErr} onHide={() => { setShowErr(false) }} size="sm">
                <Modal.Header closeButton className="border-0 headerclose">
                </Modal.Header>
                <Modal.Body className="text-center py-4">
                    <img src={require('../../src/images/check.svg').default} />
                    <h5 className="thnkutext mt-3 mb-1">Try Again!</h5>
                    <p className="mb-0">Please fill atleast one field</p>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Bodyvitals;