import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Row, Col, Tab, Nav } from 'react-bootstrap';
import { Line } from "react-chartjs-2";
import { userService } from '../_services';
import { toast } from "react-toastify";
import { Loader } from './Loader';
import { setItems } from '../utils/localStorage/index';
import Swal from 'sweetalert2';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { returnTotalPages } from '../utils/pagination';


// MODAL
import PopUpModal from '../modals';
// IMAGES
import ConsumerBadge from '../images/consumer_badge.png';
import SilverBadge from '../images/silver_badge.svg';
import BronzeBadge from '../images/bronze_badge.svg';
import DiamondBadge from '../images/diamond_badge.png';
import GoldBadge from '../images/gold_badge.svg';



const Profile = () => {

    const [usernameInfo, setuser] = useState('') as any;
    const [weeklyData, setWeeklyData] = useState({}) as any
    const [monthlyData, setMonthlylyData] = useState({}) as any
    const [showloader, setShowLoader] = useState({ display: "flex" });
    const [planDetail, setPlanDetail] = useState({
        plan: '',
        plan_status: '',
        duration: '',
        plan_id: '',
        cost: '',
        expireDate: ''
    });
    const [badgeUrl, setBadgeUrl] = useState('') as any;
    const [buisnessStatus, setBuisnessStatus] = useState('') as any;
    const [company, setCompany] = useState([]) as any;
    const [popUpModal, setPopUpModal] = useState({
        status: false,
        modalType: ''
    });
    const [companyId, setCompanyId] = useState('');
    const [associateStatus, setAssociateStatus] = useState('');
    const [pagination, setPagination] = useState({
        page: 0,
        totalPage: 0
    });
    const [isPaginationTrigger, setIsPaginationTrigger] = useState(false);

    const [searchUser, setSearchUser] = useState("") as any;
    const [isSearchTrigger, setIsSearchTrigger] = useState(false) as any;



    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem("groupTab");
        localStorage.removeItem("compTab");
        getUserProfile();
    }, []);

    // API for get user profile
    function getUserProfile() {
        let accesstoken = localStorage.getItem('accesstoken');
        userService.userdetail(accesstoken).then((response) => {
            composeProfilePageData(response.data.data);
        }).catch((error: any) => {
            if (error.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
        });
    }

    // common function to compose profile data
    function composeProfilePageData(response: any) {
        var tempWeekly = response.weekly;
        var tempMonthly = response.monthly;

        var tempWeekChart = { "labels": {}, "data": {} };
        var tempMonthChart = { "labels": {}, "data": {} };
        var weekLabels = [] as any;
        var weekData = [] as any;
        if (tempWeekly.length) {
            tempWeekly.forEach((value: any) => {
                weekLabels.push(value.day);
                if (parseFloat(value.wellness_score) >= 0) {
                    weekData.push(value.wellness_score);
                }
            })
        }

        tempWeekChart.labels = weekLabels;
        tempWeekChart.data = weekData;

        var monthLabels = [] as any;
        var monthData = [] as any;

        if (tempMonthly.length) {
            tempMonthly.forEach((value: any) => {
                monthLabels.push(value.week);
                if (parseFloat(value.wellness_score) >= 0) {
                    monthData.push(value.wellness_score);
                }
            })
        }

        tempMonthChart.labels = monthLabels;
        tempMonthChart.data = monthData;

        if (response.plan_details) {
            setItems('plan_details', response.plan_details);
            if (response.plan_details.plan_id === 3) {
                if (response.refferal_count < 1000) {
                    setBadgeUrl(BronzeBadge);
                }
                if (response.refferal_count >= 1000 && response.refferal_count <= 5000) {
                    setBadgeUrl(SilverBadge);
                }
                if (response.refferal_count > 5000) {
                    setBadgeUrl(DiamondBadge);
                }
            } else {
                let url = returnUrlofBadge(response.plan_details.plan.toLowerCase()) as any;
                setBadgeUrl(url);
            }
            setPlanDetail((prevPlanDetail: any) => {
                return {
                    ...prevPlanDetail,
                    plan: response.plan_details.plan.toLowerCase(),
                    plan_status: response.plan_details.plan_status,
                    duration: response.plan_details.duration,
                    plan_id: response.plan_details.plan_id,
                    cost: response.plan_details.cost,
                    expireDate: response.plan_details.expireDate
                }
            });
        }

        if (response.business_status) {
            setItems('business_status', response.business_status);
            if (response.business_status === "0") {
                setBuisnessStatus(response.business_status);
                // getCompanyList();
            } else {
                setBuisnessStatus(response.business_status);
            }
        } else {
            setBuisnessStatus('');
            // getCompanyList();
        }

        if (response.associate_status) {
            setAssociateStatus(response.associate_status);
            if (response.associate_status === "2") {
                getCompanyList();
            }
        } else {
            setAssociateStatus('');
            getCompanyList();
        }

        setWeeklyData(tempWeekChart);
        setMonthlylyData(tempMonthChart);
        setuser(response);
        toggleLoader("none");
    }

    // show alert if he is cancelling membership
    function confirmCancelMemberShip() {
        Swal.fire({
            text: `Are you sure you want to ${usernameInfo.renewal_status ? usernameInfo.renewal_status === "0" ? `enable` : `disable` : `enable`} auto renew feature?`,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then(e => {
            if (e.value) {
                toggleLoader("flex");
                cancelRenewal();
            }
        })
    }


    // API for cancel Subscription
    function cancelRenewal() {
        let data = {
            plan_id: `${planDetail.plan_id}`,
            id: localStorage.getItem("user_id"),
            status: usernameInfo.renewal_status ? usernameInfo.renewal_status === "0" ? `1` : `0` : `1`
        };
        userService.cancelRenewal(data).then((response: any) => {
            console.log('response...', response);
            if (response.data.data.user) {
                composeProfilePageData(response.data.data.user);
            }
            // getUserProfile();
        }).catch((error: any) => {
            console.log('error...', error)
        })
    }

    // common function for toggle loader
    function toggleLoader(status: any) {
        setShowLoader(prevShowLoader => {
            return {
                ...prevShowLoader,
                display: status
            }
        });
    }

    // get company list api
    function getCompanyList() {

        userService.getCompanyList(`?page_no=${pagination.page}${searchUser ? `&keyword=${searchUser}` : ``}`).then((response: any) => {
            if (response.data.data) {
                if (response.data.data.length) {
                    if (pagination.page >= 1) {
                        // setCompany([...company,response.data.data])
                        let array = company;
                        array.push(...response.data.data)
                        setCompany(array);
                    } else {
                        setCompany(response.data.data);
                    }

                    let totalPage = returnTotalPages(response.data.total_records, 10);
                    handlePagination('totalPage', totalPage);

                } else {
                    setCompany([]);
                    setCompanyId('');
                }
            } else {
                setCompanyId('');
            }
            setIsPaginationTrigger(false);
            toggleLoader("none");
        }).catch((error: any) => {
            console.log('Error...', error);
        })
    }

    // associate with company api
    function associateWithCompany() {
        let data = {
            company_id: companyId
        };
        userService.associateWithCompany(data).then((response: any) => {
            getUserProfile();
            triggerPopUpModal(false, 'company_list_modal')
        }).catch((error: any) => {
            console.log('error',error);
            toggleLoader("none");
        })
    }

    // on the basis of type return url of badge
    function returnUrlofBadge(type: any) {
        switch (type) {
          case 'affiliate':
            return SilverBadge;
          case 'gold affiliate':
            return GoldBadge;
          case 'xcellent affiliate':
            return DiamondBadge;
          case 'consumer':
            return ConsumerBadge;
          case 'buisness':
            return ConsumerBadge;
          case 'affiliate_bronze':
            return BronzeBadge;
          case 'affiliate_silver':
            return SilverBadge;
          default:
            return '';
        }
      }

    // return buisness status on the basis of buisness code
    function returnBuisnessStatus(type: any) {
        switch (type) {
            case "0":
                return "Pending";
            case "1":
                return "Active";
            case "2":
                return "Rejected";
            case "3":
                return "Expired";
            default:
                return "";
        }
    }

    // common function for trigger popup modal
    function triggerPopUpModal(status: Boolean, type: any) {
        setPopUpModal((prevPopUpModal: any) => {
            return {
                ...prevPopUpModal,
                status: status,
                modalType: type
            }
        });

        if(status === false && type === "company_list_modal"){
            setSearchUser('');
            toggleSearchstatus(false);
            handlePagination('page', 0);
            setIsPaginationTrigger(true);
        }
    }

    // common function to handle company id
    function toggleCompany(id: any) {
        setCompanyId(id);
    }

    // handle modal submit add company
    function handleModalSubmit() {
        if (companyId) {
            toggleLoader("flex");
            associateWithCompany();
        } else {
            toast.error("Please Select Company");
        }
    }

    // handle pagination 
    function handlePagination(type: string, value: any) {
        setPagination((prevPagination: any) => {
            return {
                ...prevPagination,
                [type]: value
            }
        })
    }

    useEffect(() => {
        if (isPaginationTrigger) {
            getCompanyList();
        }
    }, [isPaginationTrigger])

    function scrollToBottomPagination() {
        if (pagination.page + 1 < pagination.totalPage) {
            handlePagination('page', pagination.page + 1)
            toggleLoader("flex");
            setIsPaginationTrigger(true);
        } else {

        }
    }

    // handle Search in
    function handleSearchInput(event: any) {
        let { value } = event.target;
        setSearchUser(value);
    }

    // handle modal search submit
    function handleModalSearchSubmit(event: any) {
        event.preventDefault();
        if (searchUser.trim()) {
            toggleLoader("flex");
            toggleSearchstatus(true);
            handlePagination('page', 0);
            setIsPaginationTrigger(true);
        } else {
            toast.error('Input is required')
        }
        // getCompanyList();
    }

    // clear modal search input

    function clearModalSearch() {
        handlePagination('page', 0);
        toggleLoader("flex");
        setSearchUser('');
        toggleSearchstatus(false);
        setIsPaginationTrigger(true);
    }

    function toggleSearchstatus(status: any) {
        setIsSearchTrigger(status);
    }



    let modalProps = {
        company: company,
        companyId: companyId,
        isSearchTrigger: isSearchTrigger,
        searchUser: searchUser
    }, modalFuncProps = {
        clearModalSearch: clearModalSearch,
        handleModalSearchSubmit: handleModalSearchSubmit,
        handleModalSubmit: handleModalSubmit,
        handleSearchInput: handleSearchInput,
        toggleCompany: toggleCompany,
        scrollToBottomPagination: scrollToBottomPagination,
    };

    console.log('buisnessStatus', buisnessStatus);
    console.log('associateStatus...', associateStatus);
    console.log('company', company)

    return (
        <>
            <section className="profile-page py-5">
                <Loader status={showloader.display} />
                <PopUpModal
                    status={popUpModal.status}
                    modalType={popUpModal.modalType}
                    onClose={triggerPopUpModal}
                    {...modalProps}
                    {...modalFuncProps}
                />
                <div className="container">
                    <div className="inner_box ">
                        <div className="profile-inner">
                            <h3>Profile</h3>
                            <Link to="/edit_profile">Edit Profile</Link>
                        </div>
                        <hr />
                        <Row>
                            <Col md={4}>
                                {usernameInfo ? (
                                    <div className="profile-left-side">
                                        <div className="profile-image text-center">
                                            <div className="profile-img">
                                            <img src={usernameInfo.profileImage ? usernameInfo.profileImage : require('../images/profile.jpeg').default} alt="profile" className="user-profile-new" />

                                            {badgeUrl ? <img src={badgeUrl} className="profile-badge" alt="badge" /> : ''}
                                            </div>
                                            {/* {usernameInfo.profileImage ? (<img src={usernameInfo.profileImage} alt="text-center" />) : (<img src={require('../images/profile.jpeg').default} alt="profile" />)} */}
                                            {/* <img src={require('../images/profile.png').default} alt="" /> */}
                                            <h4 className="mt-4 text-capitalize">{usernameInfo.firstname} {usernameInfo.lastname}</h4>
                                            <p>Referral Code <span onClick={() => { navigator.clipboard.writeText(usernameInfo.referral_code); toast.success('Referral code copied.', { autoClose: 2500, hideProgressBar: true }) }} className="code-copy c-pointer">: {usernameInfo.referral_code}  <img className="ml-2" src={require('../images/copy.png').default} alt="copy" /></span> </p>
                                            {usernameInfo ? <p>Email: <span className="code-copy">{usernameInfo.email}</span></p> : ''}
                                            {usernameInfo ? usernameInfo.company_name ? <p>Business/Company Name:<br /> <span className="code-copy">{`${usernameInfo.company_name}  `}</span>
                                                <button className={`${returnBuisnessStatus(usernameInfo.business_status ? usernameInfo.business_status : associateStatus) ? `${returnBuisnessStatus(usernameInfo.business_status ? usernameInfo.business_status : associateStatus).toLocaleLowerCase()}-buisness` : ''} buisness-license-status`} disabled={true}>{returnBuisnessStatus(usernameInfo.business_status ? usernameInfo.business_status : associateStatus)}</button></p> : '' : ''}
                                            {usernameInfo.paypal_email ? <p>Paypal E mail <span className="code-copy">: {usernameInfo.paypal_email}</span></p> : ''}
                                            {
                                                // if user has not quote to any business plan
                                                // is user has request for business quotation or its buisness plan is active
                                                // 0 is for pending, 2 is for approved


                                                    buisnessStatus === null && associateStatus === null ? <p className="text-center"><span className="code-copy c-pointer" onClick={() => triggerPopUpModal(true, 'company_list_modal')}><u>Associate with company</u></span></p> :
                                                        buisnessStatus === "0" || buisnessStatus === "1" ? "" :
                                                            associateStatus === "0" || associateStatus === "1" ? "" :
                                                                <p className="text-center"><span className="code-copy c-pointer" onClick={() => {
                                                                    // setShowAssociate(true);
                                                                    triggerPopUpModal(true, 'company_list_modal')
                                                                }}><u>Associate with company</u></span></p>
                                            }
                                        </div>
                                        <div className="down-book">
                                            <button className="downbook-one" disabled><img src={require('../images/down.png').default} alt="down" /> {usernameInfo.height}</button>
                                            <button className="downbook-two" disabled><img src={require('../images/book.png').default} alt="book" /> {usernameInfo.weight}</button>
                                        </div>
                                        <button className="date-cart" disabled><img src={require('../images/cart.png').default} alt="cart" /> {usernameInfo.birthDate && usernameInfo.birthDate != "Invalid date" ? (<>{usernameInfo.birthDate}</>) : (<>Not Added</>)} </button>
                                        <button className="drop-cart" disabled><img src={require('../images/groop.png').default} alt="groop" /> {usernameInfo.bloodGroup}</button>

                                        {planDetail.plan_id ? <div className="subscription-plan-box">
                                            <h6>Subscription Plan</h6>
                                            <div className="subscription-name">
                                                <h4 className="text-capitalize">{planDetail.plan}</h4>
                                                {planDetail.plan === "free" ? "" : <h4 className="text-capitalize">{planDetail.duration} <span className="year-payment">{planDetail.plan === "free" ? "" : `$${planDetail.cost}`}{planDetail.duration ? `/${planDetail.duration.includes("monthly") ? "Month" : "Year"}` : ''}</span></h4>}
                                            </div>
                                            <hr />
                                            {planDetail.plan === "free" ? '' : <h6 style={{ display: "flex", justifyContent: "space-between" }}>Subscription valid till:<span> {moment(planDetail.expireDate).format('MMM DD YYYY')}</span></h6>}
                                            <div className="subscription-name">
                                                <button type="button" className="upgrade-paln-btn btn btn-unset" onClick={() => {
                                                    history.push('/membership/current');
                                                }}>Upgrade Plan</button>
                                                {
                                                    planDetail.plan === "free" ? "" : <div className="renew-box">
                                                        <h6>Auto Renew</h6>
                                                        <label className="switch">
                                                            <input type="checkbox" checked={usernameInfo.renewal_status ? usernameInfo.renewal_status === "0" ? false : true : false} onChange={() => confirmCancelMemberShip()} />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </div>
                                                }
                                            </div>
                                        </div> : ''}
                                    </div>) : (<></>)}
                            </Col>
                            <Col md={8}>
                                <div className="average-health profile_tab">
                                    <h3>Average Health Graph</h3>
                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className="mt-4">
                                        <Row>
                                            <Col sm={12}>
                                                <Nav variant="pills" className="flex-row">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first">Week</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second">Month</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col sm={12} className="mt-4">
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <div className="web_mob1">
                                                            <Line data={
                                                                {
                                                                    "labels": weeklyData.labels,
                                                                    "datasets": [
                                                                        {
                                                                            label: "Weekly data",
                                                                            backgroundColor: "#1AA0E6",
                                                                            data: weeklyData.data,
                                                                            pointRadius: 6
                                                                        }
                                                                    ],
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                }
                                                            }
                                                                options={{
                                                                    legend: {
                                                                        onClick: (e: any) => e.stopPropagation(),
                                                                        labels: {
                                                                            boxWidth: 0
                                                                        }
                                                                    },
                                                                    hover: {
                                                                        mode: 'nearest',
                                                                        intersect: true
                                                                    },
                                                                    scales:{
                                                                        yAxis:{
                                                                            min:0
                                                                        }
                                                                    }
                                                                }}

                                                            />
                                                        </div>
                                                        <div className="mob_mob1">
                                                            <Line data={
                                                                {
                                                                    "labels": weeklyData.labels,
                                                                    "datasets": [
                                                                        {
                                                                            label: "Weekly data",
                                                                            backgroundColor: "#1AA0E6",
                                                                            data: weeklyData.data,
                                                                            pointRadius: 6
                                                                        }
                                                                    ],
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                }
                                                            }
                                                                options={{
                                                                    maintainAspectRatio: true,
                                                                    legend: {
                                                                        onClick: (e: any) => e.stopPropagation(),
                                                                        labels: {
                                                                            boxWidth: 0
                                                                        }
                                                                    },
                                                                    scales:{
                                                                        yAxis:{
                                                                            min:0
                                                                        }
                                                                    }

                                                                }}

                                                            />
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <div className="web_mob1">
                                                            <Line data={
                                                                {
                                                                    "labels": monthlyData.labels,
                                                                    "datasets": [
                                                                        {
                                                                            label: "Monthly data",
                                                                            backgroundColor: "#1AA0E6",
                                                                            data: monthlyData.data,
                                                                            pointRadius: 6
                                                                        }
                                                                    ],
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                }
                                                            }
                                                                options={{
                                                                    legend: {
                                                                        onClick: (e: any) => e.stopPropagation(),
                                                                        labels: {
                                                                            boxWidth: 0
                                                                        }
                                                                    },
                                                                    scales:{
                                                                        yAxis:{
                                                                            min:0
                                                                        }
                                                                    }

                                                                }}

                                                            />
                                                        </div>
                                                        <div className="mob_mob1">
                                                            <Line data={
                                                                {
                                                                    "labels": monthlyData.labels,
                                                                    "datasets": [
                                                                        {
                                                                            label: "Monthly data",
                                                                            backgroundColor: "#1AA0E6",
                                                                            data: monthlyData.data,
                                                                            pointRadius: 6
                                                                        }
                                                                    ],
                                                                    legend: {
                                                                        display: false
                                                                    }
                                                                }
                                                            }
                                                                options={{
                                                                    maintainAspectRatio: true,
                                                                    legend: {
                                                                        onClick: (e: any) => e.stopPropagation(),
                                                                        labels: {
                                                                            boxWidth: 0
                                                                        }
                                                                    },
                                                                    scales:{
                                                                        yAxis:{
                                                                            min:0
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Profile