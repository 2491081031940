import { useState, useEffect } from "react";
import { Form } from "react-bootstrap"
import { userService, formValidation } from '../../_services';
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { Loader } from '../Loader';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { ACCEPT_NUMBER } from '../../config/constants';

const Step5 = (props: any) => {
    let history = useHistory();

    const [show, setShow] = useState("none") as any;
    const [err, setErr] = useState("") as any;
    const [bankAddress, setBankAddress] = useState("") as any;
    const [isLocationValid, setIsLocationValid] = useState(false);
    const [paymentObj, setPaymentObj] = useState({}) as any;


    useEffect(() => {
        if (props.defaultData.branchLocation) {
            setBankAddress(props.defaultData.branchLocation);
        }
        if (props.defaultData.account_holder_name) {
            handlePaymentObj("account_holder_name", props.defaultData.account_holder_name);
        }
        if (props.defaultData.accountNumber) {
            handlePaymentObj("accountNumber", props.defaultData.accountNumber);
        }
        if (props.defaultData.routingNumber) {
            handlePaymentObj("routingNumber", props.defaultData.routingNumber);
        }
        if (props.defaultData.ssn_no) {
            handlePaymentObj("ssn_no", props.defaultData.ssn_no);
        }
        if (props.defaultData.editProfile) {
            let { city, state, street, postal_code } = props.defaultData;
            if (city && state && street && postal_code) {
                setIsLocationValid(true);
            } else {
                setIsLocationValid(false);
            }
        }
    }, [])

    const handleChangeData = (e: any, fieldname: any) => {
        if (fieldname === "account_holder_name") {
            props.defaultData.account_holder_name = e;
        }

    }

    const handleBankFieldInput = (e: any, fieldname: any) => {
        console.log('ACCEPT_NUMBER.test(e)', ACCEPT_NUMBER.test(e));
        if (ACCEPT_NUMBER.test(e)) {
            handlePaymentObj(fieldname, e);
        }
    }

    const errors: any = {
        account_holder_name: "",
        accountNumber: "",
        routingNumber: "",
        location: "",
        ssn_no: "",
        // cardNumber: "",  
        // expiryDate: "",
        // cvv: "",
        formIsValid: false,
    };

    let refCode = localStorage.getItem("referral_code");

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let params = {
            account_holder_name: props.defaultData.account_holder_name,
            location: bankAddress,
            ...paymentObj
        }
        let validate = formValidation.step4_validation(params, errors);
        if (validate.formIsValid) {
            if (isLocationValid) {
                setShow("flex");
                let data = {
                    account_holder_name: params.account_holder_name,
                    routing_number: paymentObj.routingNumber,
                    account_number: paymentObj.accountNumber,
                    city: props.defaultData.city,
                    state: props.defaultData.state,
                    country: props.defaultData.country,
                    street: props.defaultData.street,
                    postal_code: props.defaultData.postal_code,
                    ssn_no: paymentObj.ssn_no
                } as any;
                userService.addBank(data).then(function (response: any) {
                    if (response.data.status) {
                        toast.success("Bank detail added successfully.");
                        setShow("none");
                        localStorage.removeItem("referral_code");

                        // OLD CODE DO NOT REMOVE
                        // history.push(refCode ? '/competition' : '/profile');

                        history.push(refCode ? '/competition' : '/membership/plan');
                    } else {
                        toast.error(response.data.message);
                        setShow("none");
                    }
                }).catch(function (error: any) {
                    setShow("none");
                });
            } else {
                toast.error('Please check location');
            }
        } else {
            setErr(validate);
        }
    }

    const handleSelect = (address: any) => {
        geocodeByAddress(address).then((result) => {
            let city = '', state = '', country = '', street = '', postal_code = '' as any;
            result.forEach((value) => {
                value.address_components.forEach((data) => {
                    if (data.types.includes("postal_code")) {
                        postal_code = data.long_name;
                    }
                    if (data.types.includes("street_number")) {
                        street = data.long_name;
                    }
                    if (data.types.includes("route")) {
                        street = street + ',' + data.long_name;
                    }
                    if (data.types.includes("administrative_area_level_1")) {
                        state = data.short_name;
                    }
                    if (data.types.includes("country")) {
                        country = data.short_name;
                    }
                    if (data.types.includes("locality")) {
                        city = data.short_name;
                    }
                })
            });
            if (city && state && country && street && postal_code) {
                if (country === "US") {
                    props.defaultData["city"] = city;
                    props.defaultData["state"] = state;
                    props.defaultData["country"] = country;
                    props.defaultData["street"] = street;
                    props.defaultData["postal_code"] = postal_code;
                    setIsLocationValid(true);
                } else {
                    toast.error('Only United States location is allowed');
                    setIsLocationValid(false);
                }
            } else {
                let object = {
                    street: street,
                    city: city,
                    state: state,
                    country: country,
                    postal_code: postal_code
                } as any;
                let errorMessage = ''
                Object.keys(object).forEach((objKey: any, index: any) => {
                    let status = object[objKey] ? false : true;
                    if (status) {
                        errorMessage += " " + objKey.replace("_", " ");
                    }
                })
                if (errorMessage) {
                    errorMessage.trim();
                    errorMessage = 'Detail of ' + errorMessage + ` are Invalid. \n Please check selected Location`;
                }
                console.log('errorMessage', errorMessage);
                toast.error(errorMessage);
                setIsLocationValid(false);
            }
        }).catch(err => {
            console.log('err...', err);
        })

    };

    const handleLocationInput = (event: any) => {
        let { value } = event.target as any;
        setBankAddress(value);
    }

    const handlePaymentObj = (name: any, value: any) => {
        setPaymentObj((prevPaymentObj: any) => {
            return {
                ...prevPaymentObj,
                [name]: value
            }
        })
    }
    console.log('paymentObj', paymentObj);

    let {
        account_holder_name,
        routingNumber,
        ssn_no,
        accountNumber
    } = paymentObj as any;

    return (
        <section className="loginsec">
            <Loader status={show} />
            <div className="right_side py-5">
                <div className="container">
                    <div className="row">
                        <aside className="col-xl-5 col-lg-7 col-sm-12 m-auto">
                            <div />
                            <div className="login_form mt-4">
                                <div className="text-center">
                                    <p className="text-right"><a href={refCode ? '/competition' : '/membership/plan'}>SKIP</a></p>
                                    <p className="text-secondary">STEP 5</p>
                                    <h4>{props.defaultData.editProfile ? 'Update' : 'Create'} Your Profile</h4>
                                    <p>Xcellent Life needs your banking information in order to make a direct payment to you when you win wellness competition or people pay you for private groups you can create.</p>
                                </div>
                                <Form className="mt-4" autoComplete="off" onSubmit={(event: any) => handleSubmit(event)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <Form.Group className="mb-3" controlId="account_holder_name">
                                                <Form.Label>Account Holder Name</Form.Label>
                                                <Form.Control type="text" name="account_holder_name" placeholder="Account holder name" className="input103 mb-3" defaultValue={account_holder_name} onChange={(e: any) => handleChangeData(e.target.value, 'account_holder_name')} minLength="5" maxLength="30" />
                                                {err ? (<span className="errors">{err.account_holder_name}</span>) : (<></>)}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="accountNumber">
                                                <Form.Label>Bank Account</Form.Label>
                                                <Form.Control type="text" placeholder="Bank Account Number" className="input103 mb-3" value={accountNumber} onChange={(e: any) => handleBankFieldInput(e.target.value, 'accountNumber')} minLength="6" maxLength="20" />
                                                {err ? (<span className="errors">{err.accountNumber}</span>) : (<></>)}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="routingNumber">
                                                <Form.Label>Routing Number</Form.Label>
                                                <Form.Control type="text" placeholder="Routing Number" className="input103 mb-3" value={routingNumber} onChange={(e: any) => handleBankFieldInput(e.target.value, 'routingNumber')} minLength="9" maxLength="9" />
                                                {err ? (<span className="errors">{err.routingNumber}</span>) : (<></>)}
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="ssn_no">
                                                <Form.Label>SSN Number</Form.Label>
                                                <Form.Control type="text" name="ssn_no" placeholder="Enter last four digits of SSN Number" className="input103 mb-3" value={ssn_no} onChange={(e: any) => handleBankFieldInput(e.target.value, 'ssn_no')} minLength="4" maxLength="4" />
                                                {err ? (<span className="errors">{err.ssn_no}</span>) : (<></>)}
                                            </Form.Group>
                                            <div className="form-group">
                                                <label htmlFor="location">Bank Address </label>
                                                <PlacesAutocomplete
                                                    value={bankAddress}
                                                    onChange={(e: any) => {
                                                        let event = {
                                                            target: {
                                                                value: e,
                                                                name: `location`,
                                                            },
                                                        };
                                                        handleLocationInput(event);
                                                    }}
                                                    onSelect={(e: any) => {
                                                        let event = {
                                                            target: {
                                                                value: e,
                                                                name: `location`,
                                                            }
                                                        };
                                                        handleSelect(e);
                                                        handleLocationInput(event);
                                                    }}>
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input
                                                                {...getInputProps({
                                                                    placeholder: 'Search Places ...',
                                                                    className: 'location-search-input form-control input103 mb-3',
                                                                })} />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => {
                                                                    const className = `suggestion-item${suggestion.active ? '--active' : ''}`;
                                                                    const style = {
                                                                        backgroundColor: suggestion.active ? '#fafafa' : '#ffffff',
                                                                        cursor: 'pointer'
                                                                    };
                                                                    return (
                                                                        <div
                                                                            {...getSuggestionItemProps(suggestion, {
                                                                                className,
                                                                                style,
                                                                            })} key={suggestion.placeId}>
                                                                            <span>{suggestion.description}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                                {err ? (<span className="errors">{err.location}</span>) : (<></>)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center mt-4">
                                        <button type="button" onClick={props.prev} className="backbtn btn shadow text-white">Back</button>
                                        <button type="submit" className="loginbtn btn shadow text-white mx-2">Done</button>
                                        <p className="forgotpassword">Profile 100% Complete</p>
                                    </div>
                                </Form>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Step5;