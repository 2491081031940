import axios from 'axios';
import moment from 'moment';
import { authHeader } from '../_helpers';
import { config } from '../config/config';
export const userService = {
  login,
  register,
  Getalldata,
  getQuestions,
  PostAnswer,
  getSurveyQuestions,
  PostSurverAnswer,
  getWebQuestions,
  PostWebAnswer,
  createProfile,
  userdetail,
  logout,
  getSecQuestions,
  confirm_security_answer,
  ChangePassword,
  add_prescription,
  get_prescription_list,
  mark_prescription_taken,
  change_password,
  body_vitals_graph,
  createProfileStep1,
  createProfileStep2,
  createProfileStep3,
  createProfileStep4,
  createProfileLast,
  blood_work_graph,
  life_style_graph,
  mood_graph,
  health_matrics,
  add_MedicalHistory,
  get_diseases,
  metric_list,
  get_MedicalHistory,
  competitionList,
  competitionDetails,
  joinCompetition,
  createGroup,
  getGroupList,
  deleteNotifs,
  getGarminToken,
  getUserListing,
  joinGroup,
  notificationList,
  groupDetail,
  paypalPayment,
  applyDiscountCode,
  friendList,
  getChat,
  getGroupChat,
  notificationSetting,
  friendRequestList,
  acceptRejectRequest,
  sendFriendRequest,
  addFriendListing,
  uploadFile,
  getChatList,
  deleteChat,
  shareApp, shareAd,
  getGarminAccessToken,
  groupGraph,
  getFitBitData,
  getGarminHealthData,
  singleFilter,
  metricFilter,
  addFilterMetric,
  filterListType,
  updateMetricFilter,
  getMetricComparisonReport,
  getMetricData,
  getTrendReportGraph,
  getGroupOfUser,
  allMetric,
  getDisease,
  getReportListing,
  createAnalysisReport,
  singleReportView,
  getIndividualGroupHealthScore,
  getIndividualGroupMetricAnalysis,
  deleteMetricFilter,
  deleteAnalysisReport,
  updateAnalysisReport,
  getNotificationList,
  getAlertStatus,
  updateAlertStatus,
  getFitBitHeartData,
  getFitBitSleepData,
  getFitBitDistanceData,
  getFitBitGlucoseData,
  getFitBitFoodLogWaterData,
  getFitBitStepsData,
  getFitBitCaloriesData,
  getFitBitBodyLogWieghtData,
  saveFitBitToken,
  getGarminAlertNotification,
  deleteNotification,
  updateFitbitGarminData,
  getAlertNotification,
  createDynamicLink,
  getStripeKeys,
  createStripeCard,
  getCards,
  deleteCards,
  payment,
  addBank,
  createUserProfile,
  singleNotification,
  markRead,
  allGroupList,
  getLinkedInAccessToken,
  healthHistoryTable,
  unfriendUser,
  cancelFriendRequest,
  surveyListing,
  groupListingReportGraph,
  surveyReportGraph,
  populationReportData,
  wellnessHealthGraph,
  subcriptionPlansFeatures,
  getPlanFinalDetails,
  subcriptionForFree,
  cancelRenewal,
  sendCompanyRequest,
  getRequestlist,
  acceptRejectUser,
  getCompanyList,
  associateWithCompany,
  deleteGroupMember,
  deleteUser
};

const configheaders = {
  headers: authHeader()
}

function login(params: any) {
  let url = `${config.apiurl_dev}/users/login`;
  return axios.post(url, params, configheaders)
}
function logout(params: any) {
  let url = `${config.apiurl_dev}/users/logout`;
  return axios.post(url, params, configheaders)
}
function register(params: any) {
  let url = `${config.apiurl_dev}/users`;
  // const headers = { headers: { appversion: 59, device_type: "w", crossDomain: true } }
  return axios.post(url, params, configheaders)
}

function userdetail(params: any) {
  let url = `${config.apiurl_dev}/users`;
  return axios.get(url, configheaders)
}

function createProfile(params: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  var formData = new FormData();
  let birthdate = moment(params.birthDate, 'MMM dd, yyyy').format('yyyy-MM-DD');
  let expiryDate = moment(params.expiryDate, 'MM-dd-yyyy').format('yyyy-MM-DD');
  formData.append("firstname", params.firstname);
  formData.append("lastname", params.lastname);
  formData.append("profileImage", params.profileImage);
  formData.append("height", params.height);
  formData.append("weight", params.weight);
  formData.append("bloodGroup", params.bloodGroup);
  formData.append("paypal_email", params.paypal_email);
  formData.append("expiryDate", expiryDate);
  formData.append("cardNumber", params.cardNumber);
  formData.append("cvv", params.cvv);
  formData.append("gender", params.gender);
  formData.append("location", params.location);
  formData.append("birthDate", birthdate);

  //  formData.append("id", params);

  formData.append("profileImage", params.profileImage);
  formData.append("accountNumber", params.accountNumber);
  formData.append("routingNumber", params.routingNumber);

  return axios.post(url, formData, configheaders)
}
function createProfileLast(params: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  var formData = new FormData();
  formData.append("city", params.city);
  formData.append("state", params.state);
  formData.append("country", params.country);
  formData.append("street", params.street);
  formData.append("postal_code", params.postal_code);


  formData.append("accountNumber", params.accountNumber);
  formData.append("routingNumber", params.routingNumber);

  return axios.post(url, formData, configheaders)
}

function createUserProfile(formData: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  return axios.post(url, formData, configheaders)
}

function createProfileStep1(params: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  var formData = new FormData();
  formData.append("firstname", params.firstname);
  formData.append("lastname", params.lastname);
  return axios.post(url, formData, configheaders)
}
function createProfileStep2(params: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  var formData = new FormData();
  formData.append("profileImage", params.profileImage);
  return axios.post(url, formData, configheaders)
}
function createProfileStep3(params: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  var formData = new FormData();
  if (params.birthDate != "") {
    let birthdate = moment(params.birthDate, 'MMM dd, yyyy').format('yyyy-MM-DD');
    formData.append("birthDate", birthdate);
  }
  formData.append("gender", params.gender);
  formData.append("location", params.location);
  formData.append("latitude", params.latitude);
  formData.append("longitude", params.longitude);
  return axios.post(url, formData, configheaders)
}

function createProfileStep4(params: any) {
  let url = `${config.apiurl_dev}/users/create_profile`;
  var formData = new FormData();
  formData.append("height", params.height);
  formData.append("weight", params.weight);
  formData.append("bloodGroup", params.bloodGroup);
  return axios.post(url, formData, configheaders)
}
function Getalldata(page: any) {
  let url: any = `${config.apiUserUrl}/get-all-feedback/` + page;
  return axios.get(url, configheaders)
}
function getQuestions() {
  let url: any = `${process.env.REACT_APP_API_URL_DEV}/questions`;
  return axios.get(url, configheaders)
}
function PostAnswer(params: any) {
  let url: any = `${process.env.REACT_APP_API_URL_DEV}/get_disease_by_code`;
  return axios.post(url, params, configheaders)
}
function getSurveyQuestions() {
  // let url: any = `${process.env.REACT_APP_API_URL_DEV}/survey_form_questions`;
  let url: any = `${config.apiurl_dev}/survey_form_questions`;
  return axios.get(url, configheaders)
}

function PostSurverAnswer(params: any) {
  // let url: any = `${process.env.REACT_APP_API_URL_DEV}/save_survey_form_question_answers`;
  let url: any = `${config.apiurl_dev}/save_survey_form_question_answers`;
  return axios.post(url, params, configheaders)
}
function getWebQuestions() {
  let url: any = `${process.env.REACT_APP_API_URL_DEV}/web_form_questions`;
  return axios.get(url, configheaders)
}
function PostWebAnswer(params: any) {
  let url: any = `${process.env.REACT_APP_API_URL_DEV}/save_web_form_questions`;
  return axios.post(url, params, configheaders)
}

function getSecQuestions(mail: any) {
  var data = {
    "email": mail
  }
  let url: any = `${config.apiurl_dev}/users/get_security_question`;
  return axios.post(url, data, configheaders)
}

function confirm_security_answer(params: any) {
  let url: any = `${config.apiurl_dev}/users/confirm_security_answer`;
  return axios.post(url, params, configheaders)
}


function ChangePassword(params: any) {
  let url: any = `${config.apiurl_dev}/users/forgot_password`;
  return axios.post(url, params, configheaders)
}

function add_prescription(params: any) {
  let url: any = `${config.apiurl_dev}/add_prescription`;
  return axios.post(url, params, configheaders)
}

function get_prescription_list(params: any) {
  let url: any = `${config.apiurl_dev}/get_prescription_list`;
  return axios.post(url, params, configheaders)
}
function mark_prescription_taken(params: any) {
  let url: any = `${config.apiurl_dev}/mark_prescription_taken`;
  return axios.post(url, params, configheaders)
}

function change_password(params: any) {
  let url: any = `${config.apiurl_dev}/users/change_password`;
  return axios.post(url, params, configheaders)
}

function body_vitals_graph() {
  var token = window.localStorage.getItem('accesstoken');
  var timeOffset = new Date().getTimezoneOffset();
  const params = JSON.stringify({
    "timezone": "" + (-timeOffset / 60)
  });
  let url: any = `${config.apiurl_dev}/body_vitals_graph`;
  return axios.post(url, params,
    { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function blood_work_graph() {
  let url: any = `${config.apiurl_dev}/blood_work_graph`;
  return axios.get(url,
    { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}


function life_style_graph() {
  let url: any = `${config.apiurl_dev}/life_style_graph`;
  return axios.get(url,
    { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function mood_graph() {
  let url: any = `${config.apiurl_dev}/mood_graph`;
  return axios.get(url,
    { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}


function health_matrics(params: any) {
  let url: any = `${config.apiurl_dev}/record_health_metrics`;
  return axios.post(url, params, configheaders)
}

function get_diseases() {
  let url: any = `${config.apiurl_dev}/get_disease`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function add_MedicalHistory(params: any) {
  let url: any = `${config.apiurl_dev}/add_medical_history`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function get_MedicalHistory() {
  let url: any = `${config.apiurl_dev}/get_medical_history`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function metric_list(data: any) {
  let url: any = `${config.apiurl_dev}/metric_list?date=${data}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function competitionList() {
  let url: any = `${config.apiurl_dev}/competition_list`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function competitionDetails(comp_id: any) {
  let body = {
    "competition_id": "" + comp_id,
    "status": "J"
  }
  let url: any = `${config.apiurl_dev}/competition_detail`;
  return axios.post(url, body, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function joinCompetition(params: any) {
  let url: any = `${config.apiurl_dev}/join_competition`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function createGroup(params: any) {
  let url: any = `${config.apiurl_dev}/create_group`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function groupDetail(params: any) {
  let url: any = `${config.apiurl_dev}/group_detail`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getGroupList() {
  let url: any = `${config.apiurl_dev}/group_list`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getUserListing() {
  let url: any = `${config.apiurl_dev}/all_user_listing`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function paypalPayment(params: any) {
  let url: any = `${config.apiurl_dev}/paypal_payment`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function applyDiscountCode(params: any) {
  let url: any = `${config.apiurl_dev}/apply_discount_code`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function friendList(params: any) {
  let url: any = `${config.apiurl_dev}/get_friend_list${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getChat(params: any) {
  let url: any = `${config.apiurl_dev}/get_chat`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function joinGroup(params: any) {
  let url: any = `${config.apiurl_dev}/join_group`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getGroupChat(data: any) {
  let url: any = `${config.apiurl_dev}/get_group_messages`;
  return axios.post(url, data, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function friendRequestList() {
  let url: any = `${config.apiurl_dev}/friend_request_list`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function acceptRejectRequest(params: any) {
  let url: any = `${config.apiurl_dev}/accept_reject_request`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function sendFriendRequest(params: any) {
  let url: any = `${config.apiurl_dev}/send_friend_request`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function addFriendListing(params: any) {
  let url: any = `${config.apiurl_dev}/user_listing`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function uploadFile(params: any) {
  let url: any = `${config.apiurl_dev}/upload_file`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getChatList(params:any) {
  let url: any = `${config.apiurl_dev}/get_chat_list${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function deleteChat(params: any) {
  let url: any = `${config.apiurl_dev}/delete_chat`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function notificationSetting(params: any) {
  let url: any = `${config.apiurl_dev}/users/notification_setting`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function notificationList(params: any) {
  let url: any = `${config.apiurl_dev}/notification_list`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function deleteNotifs() {
  let url: any = `${config.apiurl_dev}/delete_notification`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function shareAd(params: any) {
  let url: any = `${config.apiurl_dev}/share_ad`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function shareApp(params: any) {
  let url: any = `${config.apiurl_dev}/share_app`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getGarminToken() {
  let url: any = `${config.apiurl_dev}/get_garmin_request_token`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}

function getGarminAccessToken(token: any, secret: any, oauth: any) {
  let body = {
    "token": token,
    "twitter_access_token_secret": secret,
    "oauth_verifier": oauth,
  }
  let url: any = `${config.apiurl_dev}/get_garmin_access_token`;
  return axios.post(url, body, { headers: { ...configheaders.headers, ...{ "Accept": "application/json", "Content-Type": "application/json" } } })
}

function getGarminHealthData(params: any) {
  let url: any = `${config.apiurl_dev}/get_garmin_health_data`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Accept": "application/json", "Content-Type": "application/json" } } })
}

function groupGraph(params: any,) {
  let url: any = `${config.apiurl_dev}/group_graph_by_metric_id`;
  return axios.post(url, params, { headers: { ...configheaders.headers } })
}

function getFitBitData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken');
  var fitbitId = localStorage.getItem('fitbitId');
  return axios.post(`https://api.fitbit.com/1/user/${fitbitId}/activities/date/${date}.json`, "", { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitHeartData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/activities/heart/date/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitSleepData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/sleep/date/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitDistanceData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/activities/distance/date/${date}/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitGlucoseData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/glucose/date/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitFoodLogWaterData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/foods/log/water/date/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitStepsData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/activities/steps/date/${date}/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitCaloriesData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/activities/calories/date/${date}/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}
function getFitBitBodyLogWieghtData(date: any) {
  var fitbitToken = localStorage.getItem('fitbitToken')
  var fitbitId = localStorage.getItem('fitbitId')
  return axios.get(`https://api.fitbit.com/1/user/${fitbitId}/body/log/weight/date/${date}/${date}.json`, { headers: { "authorization": `Bearer ${fitbitToken}` } })
}

function metricFilter(type: string, page: number) {
  let url = `${config.apiurl_dev}/filter/list/${type}/${page}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function singleFilter(id: string) {
  let url = `${config.apiurl_dev}/view_single_filter/${id}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function addFilterMetric(data: any) {
  let url: any = `${config.apiurl_dev}/add_filter_metric`;
  return axios.post(url, data, { headers: configheaders.headers })
}

function filterListType(page: number, type: number) {
  let url: any = `${config.apiurl_dev}/filter/list/${type}/${page}`;
  // return axios.post(url, { page_no: page }, { headers: configheaders.headers })
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}

function updateMetricFilter(data: any) {
  let url: any = `${config.apiurl_dev}/update_metric_filter`;
  return axios.post(url, data, { headers: configheaders.headers });
}
function getMetricComparisonReport(id: number) {
  let url: any = `${config.apiurl_dev}/metricComparisonReport`;
  return axios.post(url, { report_id: id }, { headers: configheaders.headers });
}
function getMetricData(data: any) {
  let url: any = `${config.apiurl_dev}/getMetricdata`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getTrendReportGraph(data: any) {
  let url: any = `${config.apiurl_dev}/trendReportGraph`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getGroupOfUser() {
  let url: any = `${config.apiurl_dev}/get_Groups_of_user`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function allMetric() {
  let url: any = `${config.apiurl_dev}/all_metrics`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function getDisease() {
  let url: any = `${config.apiurl_dev}/get_disease`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function getReportListing(type: string, page: number) {
  let url = `${config.apiurl_dev}/reportListing/${type}/${page}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function createAnalysisReport(data: any) {
  let url: any = `${config.apiurl_dev}/createAnalysisReport`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function updateAnalysisReport(data: any) {
  let url: any = `${config.apiurl_dev}/updateAnalysisReport/${data.id}`;
  return axios.put(url, { ...data }, { headers: configheaders.headers });
}
function singleReportView(id: number) {
  let url = `${config.apiurl_dev}/singleView/${id}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function getIndividualGroupHealthScore(data: any) {
  let url: any = `${config.apiurl_dev}/Individual_Vs_Group_Health_Score`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getIndividualGroupMetricAnalysis(data: any) {
  let url: any = `${config.apiurl_dev}/Individual_vs_Group_Metric_Analysis_Report`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function deleteMetricFilter(data: any) {
  let url: any = `${config.apiurl_dev}/delete/metric-filter/${data}`;
  return axios.delete(url, { headers: configheaders.headers });
}
function deleteAnalysisReport(data: any) {
  let url: any = `${config.apiurl_dev}/deleteReport/${data}`;
  return axios.delete(url, { headers: configheaders.headers });
}
function getNotificationList(params: any) {
  let url: any = `${config.apiurl_dev}/get_notification_list_website/${params.page}/${params.pageLimit}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function saveFitBitToken(data: any) {
  let url: any = `${config.apiurl_dev}/saveFitbitAccessToken`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getAlertStatus() {
  let url: any = `${config.apiurl_dev}/getAlertStatus`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function updateAlertStatus(data: any) {
  let url: any = `${config.apiurl_dev}/updateAlertSetting`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getGarminAlertNotification(params: any) {
  let url: any = `${config.apiurl_dev}/getGarminAlertNotification/${params.page}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function deleteNotification(id: any) {
  let url: any = `${config.apiurl_dev}/deleteAlertNotification/${id}`;
  return axios.delete(url, { headers: configheaders.headers });
}
function updateFitbitGarminData(data: any) {
  let url: any = `${config.apiurl_dev}/updateFitbitGarminData`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getAlertNotification(params: any) {
  let url: any = `${config.apiurl_dev}/getAlertNotification/${params.page}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function createDynamicLink(data: any) {
  let url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBwEaLuBT1nD1nWqs7IqG3TNa3RqN3hT8Y`;
  return axios.post(url, { ...data }, {});
}
function getStripeKeys() {
  let url: any = `${config.apiurl_dev}/getStripeKeys`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function createStripeCard(data: any) {
  let url: any = `${config.apiurl_dev}/createStripeCard`;
  return axios.post(url, { ...data }, { headers: configheaders.headers });
}
function getCards() {
  let url: any = `${config.apiurl_dev}/getCards`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function deleteCards(id: any) {
  let url: any = `${config.apiurl_dev}/deleteCards/${id}`;
  return axios.delete(url, { headers: configheaders.headers });
}
function payment(params: any) {
  let url: any = `${config.apiurl_dev}/payment`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function addBank(params: any) {
  let url: any = `${config.apiurl_dev}/addBank`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function singleNotification(id: any) {
  let url: any = `${config.apiurl_dev}/singleNotification/${id}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function markRead(data: any) {
  let url: any = `${config.apiurl_dev}/mark_read/${data}`;
  return axios.put(url, {}, { headers: configheaders.headers });
}
function allGroupList(params: any) {
  let url: any = `${config.apiurl_dev}/all_group_list/${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function getLinkedInAccessToken(url: any) {
  return axios.post(url, {}, { headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" } });
}
function healthHistoryTable(params: any) {
  let url: any = `${config.apiurl_dev}/health_history_table${params.report_id}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function unfriendUser(id: any) {
  let url: any = `${config.apiurl_dev}/unFriend/${id}`;
  return axios.delete(url, { headers: configheaders.headers });
}
function cancelFriendRequest(id: any) {
  let url: any = `${config.apiurl_dev}/cancel_friend_request/${id}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function surveyListing() {
  let url: any = `${config.apiurl_dev}/survey_listing`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function groupListingReportGraph(params: any) {
  let url: any = `${config.apiurl_dev}/group_listing_report_graph${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function surveyReportGraph(params: any) {
  let url: any = `${config.apiurl_dev}/survey_report_graph${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function populationReportData(params: any) {
  let url: any = `${config.apiurl_dev}/population-report-data${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function wellnessHealthGraph(params: any) {
  let url: any = `${config.apiurl_dev}/wellnessHealthGraph${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function subcriptionPlansFeatures(params: any) {
  let url: any = `${config.apiurl_dev}/Subcription_plans_features${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function getPlanFinalDetails(params: any) {
  let url: any = `${config.apiurl_dev}/getPlanFinalDetails${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function subcriptionForFree(params: any) {
  let url: any = `${config.apiurl_dev}/subcriptionForFree`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function cancelRenewal(data: any) {
  let url: any = `${config.apiurl_dev}/cancelRenewal/${data.id}`;
  return axios.put(url, { ...data }, { headers: configheaders.headers });
}
function sendCompanyRequest(params: any) {
  let url: any = `${config.apiurl_dev}/sendCompanyRequest`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function getRequestlist(params: any) {
  let url: any = `${config.apiurl_dev}/getRequestlist${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function acceptRejectUser(params: any) {
  let url: any = `${config.apiurl_dev}/accept_reject/user`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function getCompanyList(params: any) {
  let url: any = `${config.apiurl_dev}/getCompanyList${params}`;
  return axios.get(url, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } });
}
function associateWithCompany(params: any) {
  let url: any = `${config.apiurl_dev}/associateWithCompany`;
  return axios.post(url, params, { headers: { ...configheaders.headers, ...{ "Content-Type": "text/json" } } })
}
function deleteGroupMember(data:any){
  let url: any = `${config.apiurl_dev}/delete_member/${data}`;
  return axios.delete(url, { headers: configheaders.headers });
}
function deleteUser(data:any){
  let url: any = `${config.apiurl_dev}/delete/${data}`;
  return axios.delete(url, { headers: configheaders.headers });
}