/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { Nav, Container, Tab, Row, Col } from 'react-bootstrap'
import { Accordion, Card } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { Link, useHistory } from 'react-router-dom';
import { userService } from '../_services';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import { Loader } from './Loader';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from 'react-bootstrap';
import { reportTypeSubtitle } from '../config/config';
import { setItems } from '../utils/localStorage/index';
import { WEEK_DAYS, REPORT_SECTION_OPTIONS } from '../config/constants';
// MODAL
import PopUpModal from '../modals';

const Dashboard = () => {
    const [tekenper, settaken] = useState('') as any;
    const [openmedi, setOpenmedi] = useState(false);
    const [medicalHistory, setMedicalHistory] = useState([]);
    const [Takenshow, setTakenShow] = useState(false);
    const takenClose = () => setTakenShow(false);
    const takenShow = () => setTakenShow(true);
    const [show, setShow] = useState("none");
    const [prescriptionList, setPrescriptionList] = useState([]);
    const [calanderDate, setcalanderDate] = useState(new Date());
    const [healthSummary, setHealthSummary] = useState(null);
    const [fitbit, setFitbit] = useState(false);
    const [garmin, setGarmin] = useState(false);
    const [preCautionData, setPreCautionData] = useState('');

    const [popUpModal, setPopUpModal] = useState({
        status: false,
        modalType: ''
    });

    const viaDetail = localStorage.getItem("viaIsDetail");
    const viaInviteGroup = localStorage.getItem("viaInviteGroup");
    const isReportClick = localStorage.getItem("isReportClick");

    const opentab = (id: any) => {
        //    alert(id)
        var element: any = document.getElementById(id);
        element.classList.toggle("show");

    }
    const handleChange = (e: any) => {
        if (e) {
            setcalanderDate(e);
            get_prescription_list(e);
        }
    }

    // "https://nlp.xcellentlife.com/symptoms/"
    let sym_url = "/symptoms/" + localStorage.getItem('user_id');

    const history = useHistory();

    useEffect(() => {
        localStorage.removeItem("groupTab")
        localStorage.removeItem("compTab")
        if (!localStorage.getItem('username') || localStorage.getItem('username') == "null null") {
            window.location.href = "/create_profile"
        }
        if (localStorage.getItem('record') && localStorage.getItem('record') == 'yes') {
            document.getElementById('left-tabs-example-tab-fourth')?.click()
            localStorage.removeItem('record')
        }
        const fetchHealthSummary = () => {
            if (localStorage.getItem('prescription') && localStorage.getItem('prescription') == 'yes') {
                document.getElementById('left-tabs-example-tab-second')?.click()
                get_prescription_list(calanderDate)
            }
            setShow("flex");
            userService.body_vitals_graph().then(response => { return response.data }).then((result) => {
                // check if user subscribed to membership then show dashboard other wise redirect to membership plan page
                if (result.data.plan_details) {
                    setItems('plan_details', result.data.plan_details);
                    setHealthSummary(result);
                    setShow("none");
                    if (result.data.business_status) {
                        setItems('business_status', result.data.business_status);
                    }
                } else {
                    if (result.data.business_status) {
                        setItems('business_status', result.data.business_status);
                    } else {
                        history.push("/membership/plan");
                    }
                }
            }).catch(error => {
                setShow("none");
                if (error.response?.status == 401) {
                    localStorage.clear()
                    window.location.href = '/'
                }
            });
        }
        getMedicalHistory();
        fetchHealthSummary();
        connectionStatus();
    }, []);

    useEffect(() => {
        if (isReportClick === "true") {
            localStorage.setItem("left_menu_option", "fifth");
            localStorage.setItem("isReportClick", "false");
            getMedicalHistory();
        }

    }, [isReportClick])

    function connectionStatus() {
        var todayDate = new Date;
        var today = todayDate.getFullYear() + "-" + todayDate.getMonth() + "-" + todayDate.getDate();
        var oauth_token = localStorage.getItem('garminauthSecret');
        var oauth_token_secret = localStorage.getItem('garminauthToken');
        var fitbitId = localStorage.getItem('fitbitId');
        if (fitbitId) {
            userService.getFitBitData(today).then(resp => {
                setFitbit(true);
            }).catch((error: any) => {
                console.log('error', error);
            })
        }
        if (oauth_token) {
            userService.getGarminHealthData({ oauth_token: oauth_token, oauth_token_secret: oauth_token_secret })
                .then(resp => {
                    if (resp.data.data.errorMessage) {
                        setGarmin(false)
                    } else {
                        setGarmin(true)
                    }
                }).catch(error => {
                    if (error.response.status == 401) {
                        localStorage.clear()
                        window.location.href = '/'
                    }
                })
        }
    }

    function getMedicalHistory() {
        userService.get_MedicalHistory().then((resp => {
            setShow("none");
            setMedicalHistory(resp.data.data)
        })).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        })
    }

    const get_prescription_list = (date: any) => {
        setShow("flex")
        let params: any = {
            "date": moment(date, 'DD-MM-YYYY').format('yyyy-MM-DD')
        };
        userService.get_prescription_list(params).then(function (response: any) {
            setPrescriptionList(response.data.data.weekly)
            settaken(response.data.data.taken_percentage)
            setTimeout(() => {
                setShow("none")
            }, 2000);

            setTimeout(() => {
                localStorage.removeItem('prescription')
            }, 5000);

        }).catch(function (error: any) {
            setShow("none")
            if (error.response?.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
        });
    }
    const mark_prescription_taken = (id: any, time: any, status: any) => {
        if (status == "N") {
            setShow("flex");

            let params: any = {
                "user_id": localStorage.getItem("user_id"),
                "prescription_id": id,
                "taken_date": moment(new Date(), 'DD-MM-YYYY').format('yyyy-MM-DD'),
                "taken_time": time
            };
            userService.mark_prescription_taken(params).then(function (response: any) {
                setShow("none");
                takenShow();
                get_prescription_list(calanderDate);
            }).catch(function (error: any) {
                setShow("none");
                if (error.response?.status == 401) {
                    localStorage.clear();
                    window.location.href = '/';
                }
            });
        }
    }
    let Listall: any = []
    if (prescriptionList.length > 0) {
        //  
        Listall = prescriptionList.map(function (li: any, index: any) {
            let event = parseInt(index + 1);
            let event1 = "inner" + parseInt(index + 1);
            let id = "little-medicine" + index;
            let ids = "inner" + index;

            // let med: any = [];
            // var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var d1 = new Date(li.date);
            var d = moment(d1, 'DD-MM-YYYY').format('MMM-DD')
            var dayName = WEEK_DAYS[d1.getDay()];
            let totalmed = li.Medicine.Morning.length + li.Medicine.Afternoon.length + li.Medicine.Evening.length + li.Medicine.Bedtime.length;

            return (
                <React.Fragment key={`${li.date}_${index}`}>
                    <Accordion >
                        <div>
                            <Accordion.Toggle as={Card.Header} eventKey={event} className="bg-transparent border-0 px-0 py-2">
                                <div className="prescription-all-progress">
                                    <p className="pb-0 mb-0"><span>{totalmed}</span>  {d}</p>
                                    <p className="ml-auto mt-3">{dayName}</p>
                                </div>
                            </Accordion.Toggle>

                            <Accordion.Collapse eventKey={event}>
                                <div className="inner_collapse">
                                    <Accordion.Toggle as={Card.Header} eventKey={event1 + "M"} className="bg-transparent border-0 px-0 py-2">
                                        <div className="medi-time" onClick={() => opentab(ids + "M")} aria-controls={id} aria-expanded={openmedi}>
                                            <img src={require('../images/medicine.png').default} alt="medicine" />

                                            <div className="medi-time-text">
                                                <h5>Morning</h5>
                                                <h6>08:00 AM</h6>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={event1 + "M"} id={ids + "M"}>
                                        <div id={id} >
                                            {li.Medicine.Morning.length == 0 ? (<div className="medi-time second-medi-time">
                                                <img src={require('../images/medicine-2.png').default} alt="medicine-2" />
                                                <div className="medi-time-text new-medi-box">
                                                    <h5>No Medication to take.</h5>
                                                </div>
                                            </div>) : (
                                                li.Medicine.Morning.map((med: any, i: any) => (
                                                    <div className="medi-time second-medi-time" key={`Morning_${i}_${i}`}>
                                                        <img src={require('../images/medicine-2.png').default} alt="medicine-2" />
                                                        <div className="medi-time-text new-medi-box" onClick={() => mark_prescription_taken(li.Medicine.Morning[i].prescription_id, li.Medicine.Morning[i].timing, med.status)}>
                                                            <h6>{li.Medicine.Morning[i].prescription_name}</h6>
                                                            {/* {li.Medicine.Morning[i].status} */}
                                                            {li.Medicine.Morning[i].status == "Y" ? (<i className="fa fa-check" aria-hidden="true" />) : (<></>)}

                                                        </div>
                                                    </div>
                                                ))
                                            )}

                                        </div>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle as={Card.Header} eventKey={event1 + "A"} className="bg-transparent border-0 px-0 py-2">
                                        <div className="medi-time" onClick={() => opentab(ids + "A")} aria-controls={id} aria-expanded={openmedi}>
                                            <img src={require('../images/medicine.png').default} alt="medicine" />
                                            <div className="medi-time-text">
                                                <h5>AfterNoon</h5>
                                                <h6>12:00 PM</h6>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={event1 + "A"} id={ids + "A"}>
                                        <div id={id + "A"}>
                                            {li.Medicine.Afternoon.length == 0 ? (
                                                <div className="medi-time second-medi-time">
                                                    <img src={require('../images/medicine-2.png').default} alt="medicine-2" /><div className="medi-time-text new-medi-box">
                                                        <h5>No Medication to take.</h5>
                                                    </div>
                                                </div>
                                            ) : (
                                                li.Medicine.Afternoon.map((med: any, i: any) => (
                                                    <div className="medi-time second-medi-time" key={`Afternoon_${i}_${i}`}>
                                                        <img src={require('../images/medicine-2.png').default} alt="medicine-2" />
                                                        <div className="medi-time-text new-medi-box" onClick={() => mark_prescription_taken(li.Medicine.Afternoon[i].prescription_id, li.Medicine.Afternoon[i].timing, med.status)}>
                                                            <h6>{li.Medicine.Afternoon[i].prescription_name}</h6>
                                                            {li.Medicine.Afternoon[i].status == "Y" ? (<i className="fa fa-check" aria-hidden="true" />) : (<></>)}
                                                        </div>
                                                    </div>
                                                ))
                                            )}

                                        </div>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle as={Card.Header} eventKey={event1 + "E"} className="bg-transparent border-0 px-0 py-2">
                                        <div className="medi-time" onClick={() => opentab(ids + "E")} aria-controls={id} aria-expanded={openmedi}>
                                            <img src={require('../images/medicine.png').default} alt="medicine" />
                                            <div className="medi-time-text">
                                                <h5>Evening</h5>
                                                <h6>04:00 PM</h6>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={event1 + "E"} id={ids + "E"}>
                                        <div id={id}>

                                            {li.Medicine.Evening.length == 0 ? (
                                                <div className="medi-time second-medi-time">
                                                    <img src={require('../images/medicine-2.png').default} alt="medicine-2" /><div className="medi-time-text new-medi-box">
                                                        <h5>No Medication to take.</h5>

                                                    </div>
                                                </div>) : (
                                                li.Medicine.Evening.map((med: any, i: any) => (
                                                    <div className="medi-time second-medi-time" key={`Evening_${i}_${i}`}>
                                                        <img src={require('../images/medicine-2.png').default} alt="medicine-2" />
                                                        <div className="medi-time-text new-medi-box" onClick={() => mark_prescription_taken(li.Medicine.Evening[i].prescription_id, li.Medicine.Evening[i].timing, med.status)}>
                                                            <h6>{li.Medicine.Evening[i].prescription_name}</h6>
                                                            {li.Medicine.Evening[i].status == "Y" ? (<i className="fa fa-check" aria-hidden="true" />) : (<></>)}
                                                        </div>
                                                    </div>
                                                )))}

                                        </div>
                                    </Accordion.Collapse>
                                    <Accordion.Toggle as={Card.Header} eventKey={event1 + "B"} className="bg-transparent border-0 px-0 py-2">
                                        <div className="medi-time" onClick={() => opentab(ids + "B")} aria-controls={id} aria-expanded={openmedi}>
                                            <img src={require('../images/medicine.png').default} alt="medicine" />
                                            <div className="medi-time-text">
                                                <h5>Bedtime</h5>
                                                <h6>08:00 PM</h6>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={event1 + "B"} id={ids + "B"}>
                                        <div id={id}>

                                            {li.Medicine.Bedtime.length == 0 ? (
                                                <div className="medi-time second-medi-time">
                                                    <img src={require('../images/medicine-2.png').default} alt="medicine-2" />
                                                    <div className="medi-time-text new-medi-box">
                                                        <h5>No Medication to take.</h5>
                                                    </div>
                                                </div>
                                            ) : (
                                                li.Medicine.Bedtime.map((med: any, i: any) => (
                                                    <div className="medi-time second-medi-time" key={`Bedtime_${i}_${i}`}>
                                                        <img src={require('../images/medicine-2.png').default} alt="medicine-2" />
                                                        <div className="medi-time-text new-medi-box" onClick={() => mark_prescription_taken(li.Medicine.Bedtime[i].prescription_id, li.Medicine.Bedtime[i].timing, med.status)}>
                                                            <h6>{li.Medicine.Bedtime[i].prescription_name}</h6>
                                                            {li.Medicine.Bedtime[i].status == "Y" ? (<i className="fa fa-check" aria-hidden="true" />) : (<></>)}
                                                        </div>
                                                    </div>
                                                ))
                                            )}

                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                </React.Fragment>
            )

        })
    }

    const LEFT_MENU_OPTIONS = [
        {
            eventKey: "first",
            image: require('../images/dashboard.png').default,
            text: "Dashboard",
            click: (event: any) => {
                setLocalStorage("left_menu_option", "first");
            }
        },
        {
            eventKey: "second",
            image: require('../images/prescription-icon.png').default,
            text: "Prescription",
            click: () => {
                setLocalStorage("left_menu_option", "second");
                get_prescription_list(calanderDate);
            }
        },
        {
            eventKey: "third",
            image: require('../images/medical_history.png').default,
            text: "Medical History",
            click: () => {
                setLocalStorage("left_menu_option", "third");
            }
        },
        {
            eventKey: "fourth",
            image: require('../images/record.png').default,
            text: "Record Data",
            click: () => {
                setLocalStorage("left_menu_option", "fourth");
            }
        },
        {
            eventKey: "fifth",
            image: "/images/news.png",
            text: "Reports",
            click: () => {
                setLocalStorage("left_menu_option", "fifth");
            }
        }
    ];

    const setLocalStorage = (key: string, value: any) => {
        localStorage.setItem(key, value);
        let isDetail = getLocalStorage("viaIsDetail");
        let isInviteGroup = getLocalStorage("viaInviteGroup");    //localStorage.setItem('viaInviteGroup', "false");

        let compId = getLocalStorage("comp_id");
        if (isDetail === "true") {
            localStorage.setItem('viaIsDetail', "false");
        }
        if (isInviteGroup === "true") {
            localStorage.setItem('viaInviteGroup', "false");
        }
        if (compId) {
            localStorage.removeItem("comp_id");
        }
    }

    const getLocalStorage = (key: string) => {
        let data = localStorage.getItem(key);
        return data ? data : false;
    }

    const back = () => {
        if (viaInviteGroup === "true") {
            let inviteGroupId = localStorage.getItem("inviteGroupId");
            if (inviteGroupId) {
                localStorage.removeItem("inviteGroupId");
                localStorage.removeItem("viaInviteGroup");
                history.push(`/invite_group?id=${parseInt(inviteGroupId)}`);
            } else {
                history.go(-1);
            }
        } else {
            let compId = localStorage.getItem("comp_id");
            if (compId) {
                history.push(`/competitiondetails?comp_id=${parseInt(compId)}`);
            } else {
                history.go(-1);
            }
        }
    }

    // common function for toggle modal
    function toggleCautionSymptom(data: any, type: any) {
        setPreCautionData(data);
        triggerPopUpModal(true, type)
    }

    // common function for trigger popup modal
    function triggerPopUpModal(status: Boolean, type: any) {
        setPopUpModal((prevPopUpModal: any) => {
            return {
                ...prevPopUpModal,
                status: status,
                modalType: type
            }
        });
        if (!status) {
            setPreCautionData('');
        }
    }

    let data = getLocalStorage("left_menu_option");

    let modalProps = {
        preCautionData: preCautionData
    }, modalFuncProps = {};

    return (
        <>
            <section className="dashboard_section py-5">
                <PopUpModal
                    status={popUpModal.status}
                    modalType={popUpModal.modalType}
                    onClose={triggerPopUpModal}
                    {...modalProps}
                    {...modalFuncProps}
                />
                <Loader status={show} />
                <Container>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={data ? `${data}` : "first"}>
                        <div className="maintabing_row">
                            <div className="left_side">
                                <Nav variant="pills" className="flex-column">
                                    {
                                        LEFT_MENU_OPTIONS.map((value: any, index: any) => {
                                            return (
                                                <Nav.Item key={`${value.eventKey}_${index}`}>
                                                    <Nav.Link eventKey={value.eventKey}>
                                                        <div className="leftside_tabing" onClick={value.click}>
                                                            <img src={value.image} alt={value.text} />
                                                            <h3>{value.text}</h3>
                                                        </div>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            )
                                        })
                                    }
                                </Nav>
                            </div>
                            <div className="right_side">
                                <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                        <div className="dashboard_content">
                                            <h2 className="mb-4">Connected Data Sources:</h2>
                                            <Row>
                                                <Col xs={6} md={4}>
                                                    <Link className="datatype" to="/connect?source=fitbit">
                                                        <img src={require('../images/fitbit.png').default} alt="fitbit" />
                                                        <span className={fitbit ? 'reddot green' : 'reddot'} />
                                                    </Link>
                                                </Col>
                                                <Col xs={6} md={4}>
                                                    <Link className="datatype" to="/connect?source=garmin">
                                                        <img src={require('../images/garmin.png').default} alt="garmin" />
                                                        <span className={garmin ? 'reddot green' : 'reddot'} />
                                                    </Link>
                                                </Col>
                                                <Col md={12}>
                                                    <hr className="my-3" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} md={6} className="mb-3">
                                                    <div className="rangebox1 mb-3">
                                                        <div className="flex-classs">
                                                            <h6>Overall Health Score</h6>
                                                            <h4>{healthSummary ? healthSummary['data']['overall_health_score'] : "0.00"}%</h4>
                                                        </div>
                                                        {
                                                            healthSummary && healthSummary['data']['overall_health_score'] == '0.00'
                                                                ?
                                                                <div className="bar1" />
                                                                :
                                                                <div className={healthSummary ? (healthSummary['data']['overall_health_score'] <= 30 ? 'bar1 box-red' : (healthSummary['data']['overall_health_score'] <= 60.0 ? 'bar1 box-yellow' : 'bar1 box-green')) : 'bar1 box-red'} style={{ width: healthSummary ? (healthSummary['data']['overall_health_score'] + "%") : "0%" }}></div>
                                                        }
                                                    </div>
                                                    <Row>
                                                        <Col md={6} className="mb-2">
                                                            <div className="bmi_box1">
                                                                <h6>BMI</h6>
                                                                <h5 className="mb-0">{healthSummary ? healthSummary["data"]["bmi"] : 0}</h5>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} className="mb-3">
                                                            <div className="blood_box1">
                                                                <h6>Blood Group</h6>

                                                                <h5 className="mb-0"><img src={require('../images/bloog_group.png').default} className="mr-2" alt="bloog_group" /> {healthSummary ? healthSummary["data"]["bloodGroup"] : "-0"}</h5>
                                                            </div>
                                                        </Col>
                                                        <Col md={12}>
                                                            <div className={healthSummary ? (healthSummary['data']['profile_percentage'] <= 40 ? 'Profile_box box-red' : (healthSummary['data']['profile_percentage'] <= 80 ? 'Profile_box box-yellow' : 'Profile_box box-green')) : 'Profile_box box-red'}>
                                                                <h5 className="text-white">Profile Completeness</h5>
                                                                <h4 className="text-white">{healthSummary ? healthSummary['data']['profile_percentage'] : "0"}%</h4>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col sm={6} md={6}>
                                                    <div className="rightside_box">
                                                        <Link className={healthSummary ? (healthSummary['data']['body_vital_status'] <= 15 ? 'box1 box-red' : (healthSummary['data']['body_vital_status'] <= 20 ? 'box1 box-yellow' : 'box1 box-green')) : 'box1 box-red'} to="/bodygraph">
                                                            <h2>Body Vitals</h2>
                                                        </Link>
                                                        <Link className={healthSummary ? (healthSummary['data']['blood_work_status'] <= 15 ? 'box1 box-red' : (healthSummary['data']['blood_work_status'] <= 20 ? 'box1 box-yellow' : 'box1 box-green')) : 'box1 box-red'} to="/bloodgraph">
                                                            <h2>Blood Work</h2>
                                                        </Link>
                                                        <Link className={healthSummary ? (healthSummary['data']['lifestyle_status'] <= 15 ? 'box1 box-red' : (healthSummary['data']['lifestyle_status'] <= 20 ? 'box1 box-yellow' : 'box1 box-green')) : 'box1 box-red'} to="/lifegraph">
                                                            <h2>Life Style</h2>
                                                        </Link>
                                                        <Link className={healthSummary ? (healthSummary['data']['mood_status'] < 0 ? 'box1 box-red' : (healthSummary['data']['mood_status'] <= 2 ? 'box1 box-yellow' : 'box1 box-green')) : 'box1 box-red'} to="/moodgraph">
                                                            <h2>Mood</h2>
                                                        </Link>

                                                    </div>
                                                </Col>

                                            </Row>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="second">
                                        <div>
                                            <div className="prescription-box">
                                                <h2>Prescription</h2>
                                                <a type="submit" className="btnsumit text-center add-description" href="/add_prescription">
                                                    + Add Prescription
                                                </a>
                                            </div>
                                            <Row>
                                                <Col md={2}>
                                                    <div className="chat3" style={{ width: 132, height: 132 }}>
                                                        <CircularProgressbar value={tekenper} text={tekenper + `%`} strokeWidth={8} styles={buildStyles({ pathColor: "#1AA0E6", trailColor: "#F6E4A9", textColor: "#345161" })} />
                                                    </div>
                                                </Col>
                                                <Col md={10} className="">
                                                    <div className="prescription-week ">
                                                        <div className="clndr3 d-inline-block position-relative clndr3_1">
                                                            <h2 className="d-inline-block border-0 mb-0 my-0 py-0"><i className="fa fa-calendar-check-o mr-2" aria-hidden="true" />Week
                                                                <DatePicker
                                                                    className="ml-2"
                                                                    format="MM-dd-y"
                                                                    dayPlaceholder="dd"
                                                                    monthPlaceholder="mm"
                                                                    autoFocus={false}
                                                                    minDate={new Date}
                                                                    // calendarIcon={Calendar}
                                                                    yearPlaceholder="yyyy"
                                                                    onChange={(e: any) => handleChange(e)}
                                                                    value={calanderDate} />
                                                                <span>
                                                                    <img src={require('../images/clndr1.svg').default} alt="clndr1" width="17px" style={{ top: "12px" }} />
                                                                </span>
                                                            </h2>
                                                        </div>
                                                        <div>
                                                            <hr className="mt-3 mb-1" />
                                                        </div>
                                                        {Listall}
                                                        <div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                        <div className="prescription-box medical-history-box">
                                            <h2>Medical History</h2>
                                            <Link to="/medical_record" className="btnsumit text-center add-description">+ Add Medical History</Link>
                                            {/* <a type="submit" className="btnsumit text-center add-description" href="/medical_record">+ Add Medical History</a> */}
                                        </div>

                                        <div style={{ maxHeight: "440px", overflowX: "hidden", overflowY: "scroll" }}>
                                            {medicalHistory.length ?
                                                medicalHistory.map((data: any, index: any) => {
                                                    return (
                                                        <div className="medical-his-content mb-3 mr-2" key={`${data.history_id}_${index}`}>
                                                            <Row>
                                                                <Col sm={9}>
                                                                    <div className="medical-his-area">
                                                                        <h5>{data.medical_for == "I" ? "Your Medical History" : data.medical_for == "F" ? "Father's Medical History" : data.medical_for == "M" ? "Mother Medical History" : "Sibling's Medical History"}</h5>
                                                                        <p>Disease: {data.disease_name}</p>
                                                                        <p>Stage: <span className="normal-text">{data.stage}</span></p>
                                                                    </div>
                                                                </Col>
                                                                <Col sm={3}>
                                                                    <div className="precaution-symptoms-bt text-center">
                                                                        <p>
                                                                            <a className="precaution-bt" onClick={() => toggleCautionSymptom(data.precautions, "precaution_modal")}>View Precautions</a>
                                                                        </p>
                                                                        <p>
                                                                            <a className="symptoms-bt" onClick={() => toggleCautionSymptom(data.symptoms, "symptom_modal")}>View Symptoms</a>
                                                                        </p>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="text-center">
                                                    <p>No medical history found</p>
                                                </div>
                                            }
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fourth">
                                        {
                                            viaDetail === "true" || viaInviteGroup === "true" ? <Row>
                                                <Col md={12} sm={12} xl={12}>
                                                    <h5 className='mb-2 cursor-pointer'>
                                                        <span onClick={back} role="button">
                                                            <img src={require('../images/back.svg').default} alt="back-btn" />
                                                        </span>
                                                    </h5>
                                                </Col>
                                            </Row> : ''
                                        }
                                        <div>
                                            <h2 className="mb-2">Record Data</h2>  <span className="symptoms"><a href={sym_url}><img src={require('../images/sym.png').default} alt="medicine" /></a></span>
                                            <Row>
                                                <Col sm={3} className="mb-2">
                                                    <a className={healthSummary ? (healthSummary['data']['body_vital_status'] <= 15 ? 'redbox1' : (healthSummary['data']['body_vital_status'] <= 20 ? 'yellowbox_1' : 'grrenbox_1')) : 'redbox1'} href="/bodyvitals">
                                                        <h2>Body Vitals</h2>
                                                    </a>
                                                </Col>
                                                <Col sm={3} className="mb-2" >
                                                    <a className={healthSummary ? (healthSummary['data']['blood_work_status'] <= 15 ? 'redbox1' : (healthSummary['data']['blood_work_status'] <= 20 ? 'yellowbox_1' : 'grrenbox_1')) : 'redbox1'} href="/bloodwork">
                                                        <h2>Blood Work</h2>
                                                    </a>
                                                </Col>
                                                <Col sm={3} className="mb-2">
                                                    <a className={healthSummary ? (healthSummary['data']['lifestyle_status'] <= 15 ? 'redbox1' : (healthSummary['data']['lifestyle_status'] <= 20 ? 'yellowbox_1' : 'grrenbox_1')) : 'redbox1'} href="/lifestyle">
                                                        <h2>Life Style</h2>
                                                    </a>
                                                </Col>
                                                <Col sm={3} className="mb-2">
                                                    <a className={healthSummary ? (healthSummary['data']['mood_status'] < 0 ? 'redbox1' : (healthSummary['data']['mood_status'] <= 2 ? 'yellowbox_1' : 'grrenbox_1')) : 'redbox1'} href="/mood">
                                                        <h2>Mood</h2>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="fifth">
                                        <div className='reports_list'>
                                            <h2 className="mb-4">Reports</h2>
                                            <span className="symptoms" />
                                            <Row>
                                                {
                                                    REPORT_SECTION_OPTIONS.map((value, index) => {
                                                        return (
                                                            <Col md={9} key={`${value.text}_${index}`}>
                                                                <div className="users_box mb-3">
                                                                    <div onClick={() => history.push(value.route)} className="w-100">
                                                                        <p className='mb-0'>{value.text}</p>
                                                                    </div>
                                                                    <div>
                                                                        <OverlayTrigger
                                                                            placement="left"
                                                                            overlay={
                                                                                <Tooltip>
                                                                                    {reportTypeSubtitle[value.subtitleType]}
                                                                                </Tooltip>
                                                                            }>
                                                                            <i className="fa fa-info-circle" style={{ color: '#1ea2e3' }} />
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="invite_btn1 px-2" onClick={() => history.push(value.route)}>
                                                                        <i className="fa fa-angle-right nextarrow" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </Container>
            </section>

            <Modal show={Takenshow} onHide={takenClose} size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton className="border-0 p-1">
                    <Modal.Title />
                </Modal.Header>
                <Modal.Body className="text-center pt-0">
                    <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                    <h5 className="mt-2 mb-3">Prescription Taken</h5>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Dashboard;