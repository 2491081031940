import { useEffect, useState } from 'react'
import { Form, Modal, Container, Button, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Loader } from './Loader';
import { Link, useHistory } from 'react-router-dom';
import { Formik } from "formik";
import * as Yup from "yup";
import { userService } from '../_services';
import { METRIC_ALERT_TYPES } from '../config/constants';
import { getItems } from '../utils/localStorage';
import { DELETE_USER_CONFIRMATION_ALERT } from '../config/constants';
import firebase from "./firebase";

const Setting = () => {
    localStorage.removeItem("groupTab");
    localStorage.removeItem("compTab");

    const [notification, setNotification] = useState(false);
    const [showloader, setShowloader] = useState({ display: "none" });
    const [show, setShow] = useState(false);

    const [oldPasswordErr, setOldPasswordErr] = useState('') as any;
    const [messages, setmessages] = useState('') as any;
    const [metricAlertSetting, setMetricAlertSetting] = useState([]) as any;

    const [changePasswordObj, setChangePasswordObj] = useState({
        new_password: '',
        confirm_password: '',
        old_password: ''
    });

    const changePasswordSchema = Yup.object().shape({
        old_password: Yup.string().required("Old Password field can’t be empty."),
        new_password: Yup.string().required("Password field can’t be empty."),
        confirm_password: Yup.string().required("Confirm Password field can’t be empty.").oneOf([Yup.ref('new_password')], 'Your passwords do not match.'),
    });

    const history = useHistory();

    // const metricAlert = ['Body Vitals', 'Blood Work', 'Life Style', 'Mood'] as any;

    useEffect(() => {
        toggleLoader("flex");
        userService.notificationSetting({ "notification": "" }).then(resp => {
            var respData = resp.data.data
            if (respData == 'Y') {
                setNotification(true)
            }
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toggleLoader("none");
        })
        getAlertSetting();
    }, []);


    // Calling API for change passwords
    const submit = (values: any) => {
        let params = { ...values };
        toggleLoader("flex");
        userService.change_password(params).then(function (response) {
            toggleLoader("none");
            toggleModal(true);
            setOldPasswordErr('');
            setmessages(response.data.message)
            localStorage.removeItem('email');
        }).catch(error => {
            if (error.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toggleLoader("none");
            setOldPasswordErr(error.response.data.message)
        });
    }

    // API for changing recieve notification status
    function handleChange(e: any) {
        toggleLoader("flex");
        var c = e.target.checked;
        var params = {
            "notification": "N"
        };
        setNotification(e.target.checked);
        if (c) {
            params.notification = "Y";
        }
        userService.notificationSetting(params).then(resp => {
            var respData = resp.data.data;
            if (respData == 'Y') {
                setNotification(true);
            }
            toast.success(resp.data.message);
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear();
                window.location.href = '/';
            }
            toggleLoader("none");
        })
    }

    // get alert setting
    function getAlertSetting() {
        userService.getAlertStatus().then(resp => {
            let response = resp.data.data.length ? resp.data.data : [];
            if (response.length) {
                composeMetricAlertSetting(response);
            }
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toggleLoader("none");
        })
    }

    // calling API of update alert setting
    function updateAlertSetting(data: any) {
        userService.updateAlertStatus(data).then(resp => {
            toast.success(resp.data.message);
            toggleLoader("none");
        }).catch(err => {
            if (err.response.status == 401) {
                localStorage.clear()
                window.location.href = '/'
            }
            toggleLoader("none");
        })
    }
    // composing data of metric alert listing along with status
    function composeMetricAlertSetting(response: any) {
        let metricArray = [] as any;
        response.forEach((value: any, index: any) => {
            let obj = { ...value };
            obj.metric_name = METRIC_ALERT_TYPES[index];
            metricArray.push(obj);
        });
        setMetricAlertSetting(metricArray);
    }

    // common function for enable and disable
    function getStatusEnableDisable(status: any) {
        let value = status ? "ENABLED" : "DISABLED";
        return value;
    }

    // incase of toggle checkbox of showing alert before update
    function handleToggleInput(event: Event) {
        let { name, checked, id } = event.target as any;
        let text = `Are you sure you want to ${checked ? `enable` : `disable`} ${id}?`;
        Swal.fire({
            text: text,
            title: "Alert",
            showCancelButton: true,
            confirmButtonText: "Yes"
        }).then(e => {
            if (e.value) {
                let metricGroupId = name.replace('metric_', "");
                let metricArray = [] as any;
                // changing status for current checkbox
                metricAlertSetting.forEach((value: any) => {
                    let obj = { ...value };
                    if (obj.metric_group_id === parseInt(metricGroupId)) {
                        obj.status = checked ? 1 : 0;
                    }
                    metricArray.push(obj);
                });
                setMetricAlertSetting(metricArray);
                // triggering loader and calling update alert API
                toggleLoader("flex");
                let apiObj = {
                    metric_group_id: parseInt(metricGroupId),
                    status: getStatusEnableDisable(checked)
                };
                updateAlertSetting(apiObj);
            }
        })
    }

    // common function for loader
    function toggleLoader(status: any) {
        setShowloader((prevShowloader: any) => {
            return {
                ...prevShowloader,
                display: status
            }
        })
    }
    // Common function for triggering modal
    function toggleModal(status: any) {
        setShow(status)
    }


    function toggleMetricAlert(event: Event) {
        if (getItems("plan_details").plan_id === 1) {
            Swal.fire({
                title: '',
                text: "This feature is not included in your current plan. To use this please upgrade your plan.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Go to Subscription Plan',
                cancelButtonText: 'Cancel'
            } as any).then((result: any) => {
                if (result.value) {
                    history.push('/membership/current');
                }
            })
        } else {
            handleToggleInput(event);
        }
    }


    function confirmDeleteAlert() {
        Swal.fire({
            title: '',
            text: DELETE_USER_CONFIRMATION_ALERT,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        } as any).then((result: any) => {
            if (result.value) {
                toggleLoader("flex");
                deleteUser()
            }
        })
    }

    function deleteUser() {
        let user_id = localStorage.getItem('user_id');
        userService.deleteUser(user_id).then((response: any) => {
            debugger
            logout();
        }).catch((error) => {

        })

    }

    const logout = () => {
        let params = {
            "deviceType": "W",
            "device_token": localStorage.getItem('firebaseToken') as any,
            "unique_device_id": localStorage.getItem('udid') as any
        };
        userService.logout(params).then(function (response) {
            toggleLoader("none");
            localStorage.clear();
            window.location.href = "/login";
        }).catch(error => {
            toast.error('Something went wrong');
        });
        // if (firebase.messaging.isSupported()) {
        //     userService.logout(params).then(function (response) {
        //         toggleLoader("none");
        //         localStorage.clear();
        //         window.location.href = "/login";
        //     }).catch(error => {
        //         toast.error('Something went wrong');
        //     });
        // } else {
        //     toggleLoader("none");
        //     localStorage.clear();
        //     window.location.href = "/login";
        // }
    }



    return (
        <>
            <section className="bodyvital_section py-5">
                <Loader status={showloader.display} />
                <Container>
                    <div className="inner_box">
                        <div className="row bodyvitals_head">
                            <div className="col-md-12">
                                <h2 className="mb-1">Settings</h2>
                            </div>
                            <div className="col-md-12">
                                <hr />
                            </div>
                        </div>
                        <Row className="setting_row">
                            <Col lg={6} md={6} sm={12}>
                                <Row className="setting_form">
                                    <Col md={12}>
                                        <div className="toggle_row">
                                            <h5>Notification Settings</h5>
                                            <Form className="mr-1" onSubmit={(event: any) => { event.preventDefault() }}>
                                                <Form.Check type="switch" id="custom-switch" checked={notification} onChange={handleChange} />
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <Link to='/addBank' className="toggle_row mt-3">
                                            <h5>Bank Account</h5><i className="fa fa-angle-right" aria-hidden="true" />
                                        </Link>
                                    </Col>
                                    <Col md={12}>
                                        <Link to='/saved_cards' className="toggle_row mt-3">
                                            <h5>My Cards</h5><i className="fa fa-angle-right" aria-hidden="true" />
                                        </Link>
                                    </Col>
                                    <Col md={12}>
                                        <Link to='/membership/current' className="toggle_row mt-3">
                                            <h5>My Subscription </h5><i className="fa fa-angle-right" aria-hidden="true" />
                                        </Link>
                                    </Col>
                                    <Col md={12}>
                                        <button className='btn toggle_row mt-3 w-100' onClick={() => confirmDeleteAlert()}>
                                        <h5>Delete Account </h5><i className="fa fa-trash error" aria-hidden="true"/>
                                        </button>
                                    </Col>
                                    <Col md={12} className="mt-4">
                                        <h2 className="mb-1 changetext">Change Password</h2>
                                        {oldPasswordErr ? (<span className="errors">{oldPasswordErr}</span>) : (<></>)}
                                    </Col>
                                    <Col md={12}>
                                        <hr className="" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12} className="">
                                        <Formik
                                            initialValues={changePasswordObj}
                                            validationSchema={changePasswordSchema}
                                            enableReinitialize={true}
                                            onSubmit={(values) => {
                                                submit(values);
                                            }}>
                                            {({ errors, touched, values, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (

                                                <Form onSubmit={handleSubmit} autoComplete="off">
                                                    <Form.Group className="mb-3" controlId="old_password" >
                                                        <Form.Label>Old Password</Form.Label>
                                                        <Form.Control type="password" name="old_password" value={values.old_password} placeholder="Enter old password" className="input103" onChange={handleChange} />
                                                        {errors.old_password && touched.old_password ? (<label id="uname-error" className="error" htmlFor="old_password"> {errors.old_password}</label>) : null}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="new_password">
                                                        <Form.Label>New Password</Form.Label>
                                                        <Form.Control type="password" name="new_password" value={values.new_password} placeholder="Enter new password" className="input103" onChange={handleChange} />
                                                        {errors.new_password && touched.new_password ? (<label id="uname-error" className="error" htmlFor="new_password"> {errors.new_password}</label>) : null}
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="confirm_password">
                                                        <Form.Label>Confirm Password</Form.Label>
                                                        <Form.Control type="password" name="confirm_password" value={values.confirm_password} placeholder=" Enter confirm password" className="input103" onChange={handleChange} />
                                                        {errors.confirm_password && touched.confirm_password ? (<label id="uname-error" className="error" htmlFor="confirm_password"> {errors.confirm_password}</label>) : null}
                                                    </Form.Group>
                                                    <Col md={12} className="justify-content-center">
                                                        <Button variant="unset" type="submit" className="btnsumit mt-3 mb-4">Submit</Button>
                                                    </Col>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6} md={6} sm={12} className="border-left">
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <div className="form-group">
                                            <h2 className="changetext">Metric Alert</h2>
                                        </div>
                                        <hr />
                                        {
                                            metricAlertSetting.length ?
                                                metricAlertSetting.map((value: any, index: any) => {
                                                    console.log('value.status', value.status)
                                                    return (
                                                        <div className="switch-row px-0" key={`${value.metric_name}_${index}`}>
                                                            <h5>{value.metric_name}</h5>
                                                            <div className="custom-control custom-switch">
                                                                <input className="custom-control-input" type="checkbox" role="switch" id={value.metric_name} name={`metric_${value.metric_group_id}`} checked={value.status ? true : false} onChange={(event: any) => toggleMetricAlert(event)} />
                                                                <label className="custom-control-label" htmlFor={value.metric_name} />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : ''
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Modal show={show} onHide={() => toggleModal(false)} size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton className="border-0 p-1"><Modal.Title /></Modal.Header>
                    <Modal.Body className="text-center pt-0">
                        <img src={require('../images/check1.svg').default} alt="check1" width="85px" />
                        <h5 className="mt-2 mb-3">{messages}</h5>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}
export default Setting;