import { useState, useEffect } from "react";
import { Form } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { userService, formValidation } from '../../_services';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import moment from 'moment';
import { Loader } from '../Loader';
import {toast} from 'react-toastify';

const Step3 = (props: any) => {
  const [value, onChange] = useState(new Date()) as any;
  const [add, setAdd] = useState(props.defaultData.location);
  const [gender, setgender] = useState(props.defaultData.gender);
  const [latLng, setLatLng] = useState({
    lat: '',
    lng: ''
  }) as any;
  const [err, setErr] = useState("") as any;
  const [show, setShow] = useState("none");

  useEffect(() => {
    if (props.defaultData.latitude) {
      setLatLng((prevLatLng: any) => {
        return {
          ...prevLatLng,
          lat: parseFloat(props.defaultData.latitude),
          lng: parseFloat(props.defaultData.longitude),
        }
      })
    }
    if(props.defaultData.birthDate){
      onChange(moment(props.defaultData.birthDate).format('YYYY-MM-DD'))
    }else{
      onChange(new Date())
    }
  }, []);

  const errors: any = {
    location: "",
    gender: "",
    birthDate: "",
    formIsValid: false,
  };
  const handlchangedata = (e: any, fieldname: any) => {
    if (fieldname == 'gender') {
      props.defaultData.gender = e;
      setgender(e);
    }
    if (fieldname == 'birthDate') {
      let month=new Date(e).getMonth()+1;
      let date=new Date(e).getDate()+'/'+month+'/'+new Date(e).getFullYear();
      let duration=moment(date, "DD/MM/YYYY").fromNow();
      console.log('duration...',duration);
      if(duration.includes('years ago')){
        if(parseFloat(duration.replace(' years ago',''))>=18){
          console.log(props.defaultData)
          onChange(e);
          props.defaultData.birthDate = e;
        }else{
          toast.error('Minimum 18 Years of age required');
        }
      }else{
        toast.error('Minimum 18 Years of age required');
      }
    }
  }
  const handleChange = (address: any) => {
    // props.defaultData.location = address
    setAdd(address)
  };
  const handleSelect = (address: any) => {
    setAdd(address)
    props.defaultData.location = address;
    // console.log( props.defaultData)
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        setLatLng(latLng)
      })
      .catch(error => console.error('Error', error));
  };

  //   if (latlng !== undefined) {
  //     params.latitude = latlng.lat
  //     params.longitude = latlng.lng
  //     values.address = latlng.lat
  //   }
  const nextstep3 = () => {
    let params = {
      gender: props.defaultData.gender,
      birthDate: props.defaultData.birthDate,
      location: props.defaultData.location
    };
    if (latLng !== undefined) {
      props.defaultData.latitude = latLng.lat;
      props.defaultData.longitude = latLng.lng;
    }
    // props.defaultData.latitude = 
    let validate = formValidation.step_validation(params, errors);
    if (validate.formIsValid) {
      setShow("flex");

      var formData = new FormData();
      if (params.birthDate != "") {
        let birthdate = moment(params.birthDate, 'MMM dd, yyyy').format('yyyy-MM-DD');
        formData.append("birthDate", birthdate);
      }
      formData.append("gender", params.gender);
      formData.append("location", params.location);
      formData.append("latitude", props.defaultData.latitude);
      formData.append("longitude", props.defaultData.longitude);

      userService.createUserProfile(formData).then(function (response: any) {
        setShow("none");
        props.next();
      }).catch(function (error: any) {
        setShow("none");
      });

      // userService.createProfileStep3(props.defaultData).then(function (response: any) {
      //   setShow("none");
      //   props.next();
      // }).catch(function (error: any) {
      //   setShow("none");
      // });
    } else {
      setErr(validate)
    }
  }

  const nextstepskip = () => {
    let params = {
      gender: props.defaultData.gender,
      birthDate: props.defaultData.birthDate,
      location: props.defaultData.location
    }
    if (latLng !== undefined) {
      props.defaultData.latitude = latLng.lat
      props.defaultData.longitude = latLng.lng
    }
    // props.defaultData.latitude = 
    let validate = formValidation.step_validation(params, errors);
    if (validate.formIsValid) {
      props.next();
    } else {
      setErr(validate);
    }
  }

  console.log('value...',value);

  return (
    <section className="loginsec">
      <Loader status={show} />
      <div className="right_side py-5">
        <div className="container">
          <div className="row">
            <aside className="col-xl-6 col-lg-7 col-sm-12 m-auto">
              <div />
              <div className="login_form mt-4">
                <div className="text-center">
                  <p className="text-right">
                    {/* <span className="skip" onClick={nextstepskip}>SKIP</span> */}
                  </p>
                  <p className="text-secondary">STEP 3</p>
                  <h4>{props.defaultData.editProfile ? 'Update' : 'Create'} Your Profile</h4>
                  <p>Proving your Gender, DOB & Location will allow the Xcellent Life to offer you greater value by indentifying content and local activities or events that with help you connect to the wellness community, improve your health and better serve your individual needs.</p>
                </div>
                <Form className="mt-4 pt-3" autoComplete="off">
                  <div className="row">
                    <div className="col-md-6">
                      <Form.Group className="mb-3" controlId="gender">
                        <h5 style={{ fontSize: "15px" }}>Gender</h5>
                        <Form.Control as="select" className="input103 mb-3" value={gender} onChange={(e: any) => handlchangedata(e.target.value, 'gender')}>
                          <option value="0">--Select--</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </Form.Control>
                        {err ? (<span className="errors">{err.gender}</span>) : (<></>)}
                      </Form.Group>
                    </div>
                    <div className="col-md-6">
                      <h5 style={{ fontSize: "15px" }}>Date of Birth</h5>
                      <div className="dob_Row">
                        <DatePicker
                          format="MM-dd-y"
                          autoFocus={false}
                          maxDate={new Date(moment().subtract(18, 'years').calendar())}
                          minDate={new Date(moment().subtract(100, 'years').calendar())}
                          // calendarIcon={Calendar}
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          onChange={(e: any) => handlchangedata(e, 'birthDate')}
                          value={new Date(value)} />
                        {err ? (<span className="errors">{err.birthDate}</span>) : (<></>)}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <Form.Group className="mb-3" controlId="location">
                        <h5 style={{ fontSize: "15px" }}>Location</h5>
                        <PlacesAutocomplete
                          value={add}
                          onChange={(e: any) => handleChange(e)}
                          onSelect={(e: any) => handleSelect(e)}>
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Places ...',
                                  className: 'location-search-input form-control input103 mb-3',
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                  // inline style for demonstration purpose
                                  const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                      })}
                                      key={suggestion.placeId}>
                                      <span>{suggestion.description}</span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                        {err ? (<span className="errors">{err.location}</span>) : (<></>)}
                        {/* <Form.Control type="text" placeholder="Enter your Location" className="input103" /> */}
                      </Form.Group>
                    </div>
                  </div>
                </Form>
                <div className="text-center mt-4">
                  <button onClick={props.prev} className="backbtn btn shadow text-white mr-2">Back</button>
                  <button onClick={nextstep3} className="loginbtn btn shadow text-white">Next</button>
                  <p className="forgotpassword">Profile 60% Complete </p>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Step3;