export const FACEBOOK = 1;
export const GOOGLE = 2;
export const INSTAGRAM = 3;
export const WECHAT = 4;
export const TELEGRAM = 5;
export const WHATSAPP = 6;
export const LINKEDIN = 7;
export const customStyles = {
    content: {
        left: '50%',
        border: 'none',
        background: '#fff',
    },
    overlay: { zIndex: 99999 }
}
// eslint-disable-next-line
export const HREF_LINK = '#';
export const LEVEL_OPTIONS = [
    {
        text: "Critically Low",
        value: "red_low"
    },
    {
        text: "Low",
        value: "yellow_low"
    },
    {
        text: "Normal",
        value: "green"
    },
    {
        text: "High",
        value: "yellow_high"
    },
    {
        text: "Critically High",
        value: "red_high"
    }
];
export const DURATION_OPTIONS = [
    {
        text: "Weekly",
        value: "Week"
    },
    {
        text: "Monthly",
        value: "Month"
    }
];
export const REPORT_TYPE = [
    {
        text: "Metrics & Conditions Heat Map Report",
        value: "1"
    },
    {
        text: "Metric/Condition vs Metric/Condition Trend Report",
        value: "2"
    },
    {
        text: "Metric Comparisons Report",
        value: "3"
    },
    {
        text: "Individual vs Group Analysis Report",
        value: "4"
    },
];
export const SELECTED_GROUP = [
    {
        text: "All Individuals",
        value: "allIndividual"
    },
    {
        text: "All Groups",
        value: "allGroups"
    },
    {
        text: "Specific Group(s)",
        value: "specificGroups"
    }
];
export const LEVEL = [
    {
        value: 'red_low',
        label: 'Critically Low'
    },
    {
        value: 'yellow_low',
        label: 'Low'
    },
    {
        value: 'green',
        label: 'Normal'
    },
    {
        value: 'yellow_high',
        label: 'High'
    },
    {
        value: 'red_high',
        label: 'Critically High'
    }];
export const DURATION = [
    {
        value: 'Week',
        label: 'Weekly'
    },
    {
        value: 'Month',
        label: 'Monthly'
    }];
export const ANALYSIS_DURATION = [
    {
        value: 'Weekly',
        label: 'Weekly'
    },
    {
        value: 'Monthly',
        label: 'Monthly'
    }];
export const SELECTED_GROUPS = [
    {
        value: 1,
        label: 'All Individuals'
    },
    {
        value: 2,
        label: 'All Groups'
    },
    {
        value: 3,
        label: 'Specific Group(s)'
    }];
export const POPULATION_HEALTH_REPORT_SELECT_GROUPS = [
    {
        value: 1,
        label: 'All Individuals'
    },
    {
        value: 2,
        label: 'Group'
    },
    {
        value: 3,
        label: 'Location'
    }
];
export const METRIC_COMPARISON_REPORT_TYPE = [
    {
        value: 'Individual',
        label: 'All Individuals'
    },
    {
        value: 'Groups',
        label: 'All Groups'
    }
];
export const INDIVIDUAL_VS_GROUP_ANALYSIS = [
    {
        value: 1,
        label: 'Body Vitals'
    },
    {
        value: 2,
        label: 'Blood Work'
    },
    {
        value: 3,
        label: 'Life Style'
    },
    {
        value: 4,
        label: 'Mood'
    },
    {
        value: 5,
        label: 'Overall Health Score'
    }
];

export const SURVEY_TYPE = [
    {
        value: 1,
        label: 'Question based'
    },
    {
        value: 2,
        label: 'Health score based'
    },
    {
        value: 3,
        label: 'Gender based'
    }
]

export const METRIC_CONDITION_TREND_REPORT_DROPDOWN = ["primary_metric_id", "secondary_condition_id", "level", "group_type"];
export const METRIC_COMPARISON_REPORT = ["metric_id", "group_type", "report_type"];
export const METRIC_CONDITION_HEAT_MAP_REPORT = ["condition_id", "level", "duration", "metric_id", "group_type"];
export const ARRAY_FORMAT_FIELD = ["primary_metric_id", "metric_id", "secondary_condition_id", "condition_id", "group_id", "disease_id"];
export const INDIVIDUAL_VS_GROUP_ANALYSIS_REPORT = ["group_type", "metric_type", "duration", "group_id"];
export const SURVEY_REPORT = ['survey_id', 'survey_type'];
export const GROUP_HEALTH_REPORT = ['group_id'];
export const SURVEY_GRAPH_HEADING = [" Percentage of people respond the survey 'options'", "Survey according to health score 'Normal','Bad' and 'Good'", "Survey according to gender 'Male' and 'Female'"];
export const POPULATION_GRAPH_REPORT = ['disease_id', "group_type", "group_id"];
export const ZOOM_PLUGIN_CHART_OPTIONS = {
    zoom: {
        limits: {
            x: { min: "original", max: "original" },
            y: { min: "original", max: "original" }
        },
        zoom: {
            wheel: {
                enabled: true // SET SCROOL ZOOM TO TRUE
            },
            pinch: {
                enabled: true
            },
            mode: "xy",
            speed: 20,
            drag: true
        },
        pan: {
            enabled: true,
            mode: "xy"
        }
    }
};

export const IMAGE_REGEX = (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i);
export const NUMBER_DECIMAL_REGEX = /^[0-9]*\.?[0-9]*$/;
export const PHONE_NUMBER_REG_EX = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const LOGIN_HEADER_MENU = [{
    url: "/",
    title: "Home"
},
{
    url: "/group",
    title: "Groups"
},
{
    url: "/competition",
    title: "Competitions"
},
{
    url: "/friends",
    title: "Friends"
},
{
    url: "/report",
    title: "Reports"
},
{
    url: "/alert",
    title: "Metric Alert"
}];

export const ADD_EDIT_GROUP_LABEL = `All Group fees will be paid out to Xcellent Life initialy. At the end of the month, Xcellent Life will pay out 80% of the group fees to the owner of this group while Xcellent 
Life will retain 20%. All retained fees gotowards improving health and wellness as well as research and development. Please check the box notifying Xcellent Life that
you have read and accept the terms for paid group creation.`;

export const BP_RANGE = ["Blood pressure systolic", "Diastolic"];
export const ACCEPT_NUMBER = /^[0-9]*$/;
export const POPULATION_HEALTH_REPORT_PROGRESS_BAR_ARRAY = ["rgb(209 0 0)", "rgb(55 188 164)", "rgb(214 46 207)", "rgb(253 174 97)", "rgb(26 160 230)"];
export const ADD_CARD_FORM_KEYS = ["card_number", "cvc", "expiry_date", "card_holder_name"];

export const CONSUMER_FREE=['Health Dashboard ', 'Setup Free Groups ', 'Setup Paid Groups ', 'Wellness Competition', ' Request and Accept Friends', ' Peer-to-Peer Text Chat', ' Peer-to-Peer Video Chat ', 'Group Chat', ' Medication Reminders ', 'Notification ', 'Medical History'];
export const CONSUMER=['Health Dashboard', 'Setup Free Groups', 'Setup Paid Groups', 'Wellness Competition', 'Request and Accept Friends', 'Peer-to-Peer Text Chat', 'Peer-to-Peer Video Chat', 'Group Chat', 'Medication Reminders', 'Notification', 'Heat Map Report', 'Health Metrics/Condition vs Health Metrics/Condition Report', 'Health Metrics Comparison Report', 'Individual vs Group Analysis Report', 'Health Metric Alerts', 'Health History Report', 'Survey Report', 'Group Health Report', 'Population Health Report', 'Wellness Competition Report', 'Medical History'];
export const AFFILIATE=['Health Dashboard', ' Setup Free Groups', ' Setup Paid Groups', ' Wellness Competition', ' Request and Accept Friends', ' Peer-to-Peer Text Chat', ' Peer-to-Peer Video Chat', ' Group Chat', ' Medication Reminders', ' Notification', ' Heat Map Report', ' Health Metrics/Condition vs Health Metrics/Condition Report', ' Health Metrics Comparison Report', ' Individual vs Group Analysis Report', ' Health Metric Alerts', ' Health History Report', ' Survey Report', ' Group Health Report', ' Population Health Report', ' Wellness Competition Report', ' Medical History'];

export const BLOOD_GROUP_TYPE=["O-", "O+", "A-", "A+", "B-", "B+", "AB-", "AB+"];
export const WEEK_DAYS=['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const REPORT_SECTION_OPTIONS = [
    {
        route: "/report/list?item=1",
        text: 'Metrics & Conditions Heat Map Report',
        subtitleType: "MetricsHeatMap"
    },
    {
        route: "/report/list?item=2",
        text: 'Metrics/Condition vs Metrics/Condition Trend Report',
        subtitleType: "MetricsConditions"
    },
    {
        route: "/report/list?item=3",
        text: 'Metrics Comparison Report',
        subtitleType: "MetricsComparison"
    },
    {
        route: "/report/list?item=4",
        text: 'Individual vs Group Analysis Report',
        subtitleType: "MetricsIndividualAndGroupAnalysis"
    },
    {
        route: "/report/list?item=5",
        text: 'Health History Report',
        subtitleType: "MetricsHealthHistoryReport"
    },
    {
        route: "/report/list?item=6",
        text: 'Survey Report',
        subtitleType: "MetricsSurveyReport"
    },
    {
        route: "/report/list?item=7",
        text: 'Group Health Report',
        subtitleType: "MetricsGroupHealthReport"
    },
    {
        route: "/report/list?item=8",
        text: 'Population Health Report',
        subtitleType: "MetricsPopulationHealthReport"
    },
    {
        route: "/report/list?item=9",
        text: 'Wellness Competition Report',
        subtitleType: "MetricsWellnessCompetitionReport"
    }
];
export const METRIC_ALERT_TYPES=['Body Vitals', 'Blood Work', 'Life Style', 'Mood'];
export const PRESCRIPTION_CATEGORY=["Morning", "Afternoon", "Evening", "Bedtime"];
export const PRESCRIPTON_CATEGORY_VALUES=["M", "A", "E", "B"];
export const GRAPH_COLOR_ARRAY=["#D10000", "#37BCA4", "#D62ECF"];
export const DELETE_USER_CONFIRMATION_ALERT="Are you sure you want to delete your account? If you later reactivate your account, your data cannot be restored. This includes information about your groups, chats, competitions, friend lists, and reports. Any pending payment will be canceled.";
export const SIGN_UP_QUESTION=["What is the name of the person you first kissed?", "What is the name of teacher who gave you first failing grade?", "What is the name of place where your marriage reception was held?", "What is name of first school you attended?", "Where you were when you first heard about 9/11?", "What is your oldest cousin's first and last name?"];