import { Modal } from 'react-bootstrap';

function PrecautionSymptomModal(props: any) {
    let { status, preCautionData, modalType } = props;
    return (
        <Modal show={status} onHide={() => props.onClose(false, '')} size="md" centered backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton className="border-0 p-1" />
            <Modal.Body className="text-center pt-0 precaution-modal-main">
                <h5 className="mt-2 mb-3">{modalType === "precaution_modal" ? "Precautions" : "Symptoms"}  </h5>
                <p>
                    {preCautionData}
                </p>
            </Modal.Body>
        </Modal>
    )
}

export default PrecautionSymptomModal;